import { Dialog, Transition } from '@headlessui/react'
import { nanoid } from 'nanoid/non-secure'
import { Fragment, useState } from 'react'
import axios from 'axios'
import _debounce from 'lodash/debounce'

function createButton (sampleData = false) {
  return {
    id: nanoid(),
    label: sampleData ? 'Click Me' : '',
    method: 'get',
    url: sampleData ? 'https://httpbin.org/get' : '',
  }
}

export function ModuleViewer ({ payload }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [status, setStatus] = useState({})
  const [error, setError] = useState(null)

  const buttons = payload?.buttons || [createButton()]
  const clearStatus = _debounce(() => setStatus({}), 3000)

  async function onButtonClick (button) {
    setError(null)
    setStatus({ ...status, [button.id]: 'loading' })
    try {
      if (!button.url) throw new Error('Missing URL ...')
      const res = await axios({
        method: button.method,
        url: button.url,
      })
      console.info('http response', res.data)
      setStatus({ ...status, [button.id]: 'success' })
    } catch (error) {
      console.error(error)
      setError(error)
      setDialogOpen(true)
      setStatus({ ...status, [button.id]: 'error' })
    } finally {
      clearStatus()
    }
  }

  return buttons.length ? (
    <div
      className="flex flex-wrap items-start gap-6 h-full p-3"
    >
      {/* Dummy edit button as placeholder */}
      <div className="rounded text-white bg-gray-700 hover:bg-gray-600 shadow px-4 py-2">
        Edit
      </div>

      {/* Actual buttons */}
      {buttons.map(button => (
        <button
          className={`relative rounded text-white shadow px-4 py-2 ${button.colour || 'bg-gray-700'} filter hover:brightness-125`}
          key={button.id}
          onClick={() => onButtonClick(button)}
        >
          {button.label
            ? (<span>{button.label}</span>)
            : (<span className="text-gray-500">[Label missing]</span>)
          }
          {status[button.id] ? (
            <div className="absolute inset-0 rounded bg-gray-800/75 leading-10">
              <div className={status[button.id] === 'loading' ? 'animate-spin' : ''}>
                {status[button.id] === 'loading' && '⏳'}
                {status[button.id] === 'error' && '⚠️'}
                {status[button.id] === 'success' && '✅'}
              </div>
            </div>
          ) : ''}
        </button>
      ))}

      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-md rounded bg-white p-6">
              <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                Error
              </Dialog.Title>

              <p className="my-6">
                { error?.response?.data || error?.message }
              </p>

              <button
                className="rounded-md border border-transparent bg-gray-200 hover:bg-gray-300 transition-colors px-4 py-2"
                onClick={() => setDialogOpen(false)}
              >
                Close
              </button>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[No buttons added]</span>
    </div>
  )
}

function ModuleEditorItem ({ button, onUpdate, onRemove }) {
  function updateItem (key, value) {
    const update = { ...button, [key]: value }
    onUpdate(update)
  }

  const colours = ['bg-gray-500', 'bg-red-500', 'bg-indigo-500', 'bg-green-600', 'bg-teal-600']

  return (
    <div className="rounded bg-gray-200 shadow p-3 space-y-2">
      <div className="flex items-center">
        <label
          className="flex-none w-16 font-semibold text-gray-400 text-sm"
          htmlFor={`button-label-${button.id}`}
        >
          Label
        </label>
        <input
          id={`button-label-${button.id}`}
          className="flex-none mr-2 w-36 px-2 h-8 shadow-sm sm:text-sm rounded"
          type="text"
          placeholder="Label ..."
          value={button.label || ''}
          onChange={(e) => updateItem('label', e.target.value)}
        />
        {colours.map((colour) => {
          const c = button.colour || colours[0]

          return (
            <button  
              className={`mx-0.5 w-6 h-6 ${colour} ${(c === colour) && 'border-2 border-gray-900'} rounded-full`} 
              onClick={() => updateItem('colour', colour)}>
            </button>)
        })}
        <span className="flex-auto"></span>
        <button
          className="flex-none text-xs text-gray-500 hover:text-gray-700"
          onClick={onRemove}
        >
          Remove
        </button>
      </div>
      <div className="flex items-center">
        <div className="flex-none w-16 font-semibold text-gray-400 text-sm">
          Target
        </div>
        <select
          className="flex-none w-20 pl-2 pr-5 h-8 bg-white shadow-sm sm:text-sm rounded-l"
          value={button.method || 'get'}
          onChange={(e) => updateItem('method', e.target.value)}
        >
          <option value="get">GET</option>
          <option value="post">POST</option>
        </select>
        <input
          type="text"
          className="flex-auto px-2 h-8 shadow-sm w-full sm:text-sm rounded-r border-l"
          placeholder="Paste URL..."
          value={button.url || ''}
          onChange={(e) => updateItem('url', e.target.value)}
        />
      </div>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {
  const buttons = payload?.buttons || []

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  function addItem () {
    const newItem = createButton(buttons.length === 0)
    const update = [ ...buttons, newItem]
    onUpdate('buttons', update)
  }

  function removeItem (id) {
    const update = buttons.filter(item => item.id !== id)
    onUpdate('buttons', update)
  }

  function updateItem (item) {
    const index = buttons.findIndex(b => b.id === item.id)
    const update = buttons.slice()
    update.splice(index, 1, item)
    onUpdate('buttons', update)
  }

  return (
    <div className="space-y-3">
      <p className="text-sm text-gray-600">
        Add buttons to trigger simple API requests. It shows if the request was sucessful or not. The return value is ignored.
      </p>
      <div>

      </div>
      <ul className="space-y-2">
        {buttons.map(button => (
          <li key={button.id}>
            <ModuleEditorItem
              button={button}
              onUpdate={updateItem}
              onRemove={() => removeItem(button.id)}
            />
          </li>
        ))}
        <li>
          <button
            className="px-4 h-8 text-xs font-medium rounded shadow-sm text-white bg-gray-500 hover:bg-gray-600"
            onClick={addItem}
          >
            + Add Button
          </button>
        </li>
      </ul>
    </div>
  )
}
