export function ModuleViewer({ payload }) {
  return payload?.videoId ? (
    <iframe
      title="YouTube live chat"
      width="100%"
      height="100%"
      src={`https://www.youtube.com/live_chat?v=${payload?.videoId}&embed_domain=${window.location.hostname}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[YouTube Video ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  const ytlink = {
    get: () => (payload?.videoId && payload.videoId.length === 11 ? `https://youtu.be/${payload.videoId}` : payload?.videoId || ''),
    set: (value) => {
      const regex = /(?:youtu(?:.*\/v\/|.*v=|\.be\/))?([A-Za-z0-9_-]{11})/
      const matches = value.match(regex)
      onUpdate('videoId', matches?.[1] || value)
    },
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="youtube-url" className="block text-sm font-medium text-gray-700">
          Youtube URL or Video ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="youtube-url"
            id="youtube-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://youtu.be/..."
            value={ytlink.get()}
            onChange={(e) => ytlink.set(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
