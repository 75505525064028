import { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom'
import ScreenHeader from './ScreenHeader.js'
import ScreenContent from './ScreenContent.js'
import ScreenFooter from './ScreenFooter.js'
import { doc, setDoc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore'
import { customAlphabet } from 'nanoid/non-secure'
import moduleTypes from './modules/moduleTypes.js'
import { db, getAuthUser, getUserScreenCount } from '../firebase.js'
import _debounce from 'lodash/debounce'
import useQuery from '../utils/useQuery'
import { DASHBOARD_LIMIT } from './util/limits.js'
import ModalUpgrade from './modals/ModalUpgrade.js'
import { useIsPaidUser } from './util/useIsPaidUser.js'

export default function Screen() {
  const { screenId } = useParams()
  const [searchParams] = useSearchParams()
  const [screen, setScreen] = useState({})
  const [showHeaderFooter, setShowHeaderFooter] = useState(true)
  const [user, setUser] = useState(null)
  const [dashboardLimitHit, setDashboardLimitHit] = useState(false)
  const [mouseActive, setMouseActive] = useState(false)

  const editingDisabled = screen?.uid && screen?.uid !== user?.uid
  const navigate = useNavigate()
  const query = useQuery()
  const isPaidUser = useIsPaidUser(user)

  //
  // Show hide footer button when wiggling the mouse
  //
  const onMouseMove = () => {
    setMouseActive(true)
    setMouseInactive()
  }
  const setMouseInactive = _debounce(() => setMouseActive(false), 1500)

  //
  // Handle change name
  //
  const onChangeName = useCallback(async (name) => {
    if (screen.name === name) return
    setScreen({ ...screen, name })
    const docRef = doc(db, 'screens', screenId)
    await updateDoc(docRef, { name, updated: new Date() })
  }, [screen, screenId])

  //
  // Handle change layout
  //
  const onChangeLayout = useCallback(async (layout) => {
    setScreen({ ...screen, layout })
    const docRef = doc(db, 'screens', screenId)
    await updateDoc(docRef, { layout, updated: new Date() })
  }, [screen, screenId])

  //
  // Define screen section memos
  //
  const screenHeaderMemo = useMemo(() => (
    <ScreenHeader
      screenId={screenId}
      screen={screen}
      onChangeName={onChangeName}
      disabled={editingDisabled}
    />
  ), [screenId, screen, onChangeName, editingDisabled])
  const screenContentMemo = useMemo(() => <ScreenContent screen={screen} disabled={editingDisabled} />, [screen, editingDisabled])
  const screenFooterMemo = useMemo(() => (
    <ScreenFooter
      screenId={screenId}
      screen={screen}
      onChangeLayout={onChangeLayout}
      onHideFooter={() => setShowHeaderFooter(false) }
      disabled={editingDisabled}
    />
  ), [screenId, screen, onChangeLayout, setShowHeaderFooter, editingDisabled])

  const handleGetScreenInfo = async (copyFromId) => {
    let data = await getDoc(doc(db, 'screens', copyFromId), (doc) => {
      return doc.data()
    })

    return data.data()
  }

  const handleGenerateScreen = useCallback(async (copyFromId) => {
    const user = await getAuthUser()
    const count = await getUserScreenCount()

    if((count >= DASHBOARD_LIMIT && !isPaidUser)) {
      return setDashboardLimitHit(true)
    }

    let copyFromIdInfo
    if(copyFromId) copyFromIdInfo = await handleGetScreenInfo(copyFromId)

    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
    const id = nanoid(8)
    const d = new Date()
    try {
      const screen = {
        uid: user?.uid || null,
        layout: copyFromIdInfo?.layout || 'layout4',
        created: d,
        updated: d,
        name: copyFromIdInfo?.name ? `${copyFromIdInfo?.name} (copy)` : '',
        customLayout: copyFromIdInfo?.customLayout ? copyFromIdInfo?.customLayout : [] ,
        modules: copyFromIdInfo?.modules ||  [
          {
            type: moduleTypes.YOUTUBE,
            payload: { videoId: 'jfKfPfyJRdk', autoplay: true },
          },
          {
            type: moduleTypes.STAGETIMER,
            payload: { roomId: 'TODDEMO1' },
          },
          {
            type: moduleTypes.WELCOME,
          },
          {
            type: moduleTypes.PLACEHOLDER,
          },
          {
            type: moduleTypes.PLACEHOLDER,
          },
          {
            type: moduleTypes.PLACEHOLDER,
          },
        ],
      }
      await setDoc(doc(db, 'screens', id), screen)

      return navigate(id, { replace: true })
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }, [isPaidUser, navigate])

  const screenPermissionCheck = () => {
    let userHasPermission


    switch (screen?.permissions?.setting) {
    case 'only-me':
      userHasPermission = user?.uid === screen?.uid
      break
    case 'only-emails':
      userHasPermission = user?.uid === screen?.uid || screen?.permissions?.emails?.includes(user?.email)
      break

    default:
      userHasPermission = true
      break
    }
    // !['', 'anyone'].includes(screen?.permissions?.setting) && (user?.uid !== screen?.uid && screen?.permissions?.emails?.includes(user?.email))
    return userHasPermission
  }

  // Load user from firebase
  useEffect(() => { loadUser() }, [])
  async function loadUser () {
    const user = await getAuthUser()
    setUser(user)
  }

  // Generate screen if ID is missing
  useEffect(() => {
    let unsub

    if ([true, false].includes(isPaidUser)) {
      if (!screenId) {
        console.info('Missing screen ID, generating new')
        const copyFromId = searchParams.get('copy_from')

        handleGenerateScreen(copyFromId)
      } else {
        unsub = onSnapshot(doc(db, 'screens', screenId), (doc) => {
          setScreen(doc.data())
        })
      }
    }

    return unsub
  }, [screenId, isPaidUser, searchParams, handleGenerateScreen])

  // Get hide-footer value from query
  useEffect(() => {
    if (query.get('hideFooter') === '1') setShowHeaderFooter(false)
  }, [query])

  if (dashboardLimitHit) {
    return (
      <main className="flex items-center justify-center h-screen text-white">
        <ModalUpgrade
          show
          heading='Dashboard limit reached'
          body={<span>You've already created {DASHBOARD_LIMIT} dashboards, which is the limit on the free plan.</span>}
          onClose={()=>{navigate('/dashboards')}}
        />
      </main>
    )
  }

  if (isPaidUser === null) {
    return (
      <main className="flex items-center justify-center h-screen text-white">
        <div className="text-xl font-semibold">Checking Permissions...{isPaidUser}</div>
      </main>
    )
  }

  if (!screenId) {
    return (
      <main className="flex items-center justify-center h-screen text-white">
        <div className="text-xl font-semibold">Generating Dashboard...</div>
      </main>
    )
  }

  // `screen?.permissions` ensures this locked section doesn't appear to everyone during render
  if (screen?.permissions && !screenPermissionCheck()) {
    return (
      <main className="flex flex-col items-center justify-center space-y-4 h-screen text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
        </svg>

        <div className="text-xl font-semibold max-w-xs text-center">
          <span>This dashboard has been locked by the creator</span>
          <br />
          <span className='text-sm text-gray-500'>Try {' '}
            <Link to='/login' className='underline'>logging in</Link> to get access.</span>
        </div>
      </main>
    )
  }

  return (
    <section
      onMouseMove={onMouseMove}
      className="relative h-screen flex flex-col justify-between overflow-hidden"
    >
      <header
        className={`
          z-10 transition-all duration-500 w-full
          ${showHeaderFooter ? 'relative translate-y-0 top-0' : 'absolute -translate-y-full'}
        `}
      >
        <div className="h-12 bg-gray-800 text-sm text-gray-300 rounded-lg border-4 border-gray-900 -mb-1">
          {screenHeaderMemo}
        </div>
      </header>
      <div className="flex-1 max-h-screen min-h-0">
        {screenContentMemo}
      </div>
      <footer
        className={`
          z-10 transition-all duration-500 w-full bottom-0
          ${showHeaderFooter ? 'relative translate-y-0' : 'absolute translate-y-full'}
        `}
      >
        <div className="px-4 py-2 bg-gray-800 text-sm text-gray-300 rounded-lg border-4 border-gray-900 -mt-1">
          {screenFooterMemo}
        </div>
      </footer>
      <button
        className={`
          absolute z-50 left-2 -bottom-px w-max px-2 py-1 transform transition-opacity
          text-gray-300 bg-gray-800 hover:bg-gray-700 rounded-t border-4 border-b-0 border-gray-900
          ${showHeaderFooter ? 'hidden' : 'block'}
          ${mouseActive ? 'opacity-100' : 'opacity-0'}
        `}
        onClick={() => setShowHeaderFooter(!showHeaderFooter)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
          />
        </svg>
      </button>
    </section>
  )
}
