import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Spinner from './components/util/Spinner.js'
import { getAuthUser } from './firebase.js'
import Home from './components/Home.js'
import Login from './components/Login.js'
import Signup from './components/Signup.js'
import Logout from './components/Logout.js'
import Screen from './components/Screen.js'
import Device from './components/Device.js'
import Checkout from './components/profile/Checkout.js'
import CheckoutSuccess from './components/profile/CheckoutSuccess.js'
import Dashboards from './components/profile/Dashboards.js'
import Devices from './components/profile/Devices.js'
import Profile from './components/profile/Profile.js'
import NotFoundPage from './components/NotFoundPage.js'
import { Helmet } from 'react-helmet'

// Navigational Guards
async function requireAuth (navigate, from) {
  const user = await getAuthUser()
  if (!user) {
    const next = window.location.href.replace(window.location.origin, '')
    return navigate(`/login?next=${next}`, { replace: true })
  }
}

function App() {
  return (<>
    <Helmet titleTemplate="%s | Dashmaster 2k"></Helmet>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/signup"
          element={<Signup />}
        />
        <Route
          path="/logout"
          element={<Logout />}
        />
        <Route
          path="/d/:deviceId"
          element={<Device />}
        />
        <Route
          path="/s/:screenId"
          element={<Screen />}
        />
        <Route
          path="/s"
          element={<Screen />}
        />
        <Route
          path="/devices"
          element={<PageLoader element={<Devices />} beforeEnter={[requireAuth]} />}
        />
        <Route
          path="/dashboards"
          element={<PageLoader element={<Dashboards />} beforeEnter={[requireAuth]} />}
        />
        <Route
          path="/profile"
          element={<PageLoader element={<Profile />} beforeEnter={[requireAuth]} />}
        />
        <Route
          path="/checkout"
          element={<PageLoader element={<Checkout />} beforeEnter={[requireAuth]} />}
        />
        <Route
          path="/checkout-success"
          element={<PageLoader element={<CheckoutSuccess />} beforeEnter={[requireAuth]} />}
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </BrowserRouter>
  </>)
}

function PageLoader ({ element, beforeEnter = [] }) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => { checkBeforeEnter() })
  async function checkBeforeEnter () {
    for (const callback of beforeEnter) {
      await callback(navigate)
    }
    setLoading(false)
  }

  return !loading ? element : (
    <main>
      <div className="h-screen flex items-center justify-center gap-4">
        <div className="w-10">
          <Spinner/>
        </div>
        <div className="text-gray-400 font-semibold text-xl">
          Loading Dashmaster2k ...
        </div>
      </div>
    </main>
  )
}

export default App
