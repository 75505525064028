import ModalWrapper from './ModalWrapper.js'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import { useState, useEffect } from 'react'

export default function ModalColorPicker ({
  show,
  heading = 'Choose a color ...',
  closable = true,
  onClose,
  onAccept,
  hexCode = '#ffffff',
}) {
  const [color, setColor] = useState('#ffffff')

  useEffect(() => {
    setColor(hexCode)
  }, [hexCode])

  function onWrapperAccept () {
    onAccept(color)
    onClose()
  }

  return (
    <ModalWrapper
      show={show}
      heading={heading}
      closeable={closable}
      onClose={onClose}
      onAccept={onWrapperAccept}
      width="360"
    >
      <div className="text-center">
        <HexColorPicker
          className="mx-auto mb-6"
          color={color}
          onChange={setColor}
        />
        <HexColorInput
          className="px-2 h-8 shadow-sm rounded text-center"
          color={color}
          onChange={setColor}
          prefixed
        />
      </div>
    </ModalWrapper>
  )
}
