import React from 'react'
import _random from 'lodash/random'

export function ModuleViewer({ payload }) {
  const point0 = { x: 869, y: 125 }
  const points = [
    { x: 837, y: 185 },
    { x: 461, y: 232 },
    { x: 262, y: 232 },
    { x: 577, y: 510 },
    { x: 907, y: 523 },
    { x: 926, y: 223 },
    { x: 1362, y: 242 },
    { x: 1460, y: 504 },
  ]
  const circles = points.map(p => ({ ...p, r: _random(8, 18) }))
  const lines = points.map((p, i) => {
    const p0 = point0
    const Dx = p.x - p0.x
    return `M ${p0.x} ${p0.y} C ${p0.x} ${p0.y - 20}, ${p.x - Dx/4} ${p.y/2}, ${p.x} ${p.y}`
  })

  return (
    <div
      className="bg-gray-900 h-full"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1600 800"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <image
          width="1600"
          height="800"
          href="/img/integrations/vanity-satellite-map.svg"
        />
        <g>
          <circle cx={point0.x} cy={point0.y} r="5" fill="#0ABACA"></circle>
          {circles.map((circle, i) => (
            <g transform={`translate(${circle.x}, ${circle.y})`} key={'circle' + i}>
              <circle r={circle.r + 5} fill="#0B8C98" className="opacity-50 animate-ping"></circle>
              <circle r={circle.r} fill="#0B8C98"></circle>
            </g>
          ))}
          {lines.map((line, i) => (
            <g key={'line' + i}>
              <path
                d={line}
                stroke="#0ABACA"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDashoffset="100"
                strokeDasharray="1 100"
                fill="transparent"
              >
                <animate attributeName="stroke-dashoffset" values="101;0" dur="1.5s" repeatCount="indefinite" fill="freeze" />
              </path>
              <path
                key={'line' + i}
                d={line}
                className="opacity-30"
                stroke="#0ABACA"
                strokeWidth="2"
                strokeLinecap="round"
                fill="transparent"
              />
            </g>
          ))}
        </g>
      </svg>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  return (
    <div className="space-y-3">
      {/* <label htmlFor="slido-url" className="block text-sm font-medium text-gray-700">
         No settings to configure... yet.
      </label> */}
    </div>
  )
}
