import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { Transition } from '@headlessui/react'


function Portal({ children }) {
  return createPortal(children, document.getElementById('modal-root'))
}

export default function ModalWrapper ({
  show = false,
  children,
  heading = '',
  closeable = true,
  onClose,
  onAccept,
  cancelButtonText = 'Cancel',
  acceptButtonText = 'Accept',
  loading = false,
  hideFooter = false,
  width = 800,
}) {
  const modalChildRef = useRef()

  const handleOnClose = () =>{
    if(!closeable) return
    setTimeout(() => onClose(), 150)
  }

  const handleOutsideClick = (event) => {
    event.stopPropagation()
    if (modalChildRef?.current.contains(event.target)) return
    if (loading) return

    handleOnClose()
  }

  return (
    <Portal>
      <Transition
        show={show}
        id="modal-wrapper"
        className="fixed inset-0 z-30 overflow-y-scroll"
        onMouseDown={handleOutsideClick}
      >
        {/* Background */}
        <Transition.Child
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed z-10 inset-0 bg-gray-900/70"
        />

        <div className="relative z-20 min-h-full py-12 flex items-center w-full px-8">
          {/* Modal */}
          <Transition.Child
            ref={modalChildRef}
            enter="transition duration-300 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition duration-300 ease-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className="mx-auto bg-gray-100 w-full divide-y-2 divide-gray-200/50 rounded"
            style={{ maxWidth: `${width}px` }}
          >
            {/* Modal Header */}
            <div className='px-6 py-4 flex justify-between items-center'>
              <h2 className='text-xl font-bold'>{heading}</h2>
              <button disabled={!closeable} className='p-2 hover:bg-gray-200 rounded transition-colors disabled:pointer-events-none disabled:opacity-0' onClick={handleOnClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className='px-6 py-4 space-y-4'>
              {children}
            </div>

            {/* Modal Footer */}
            {hideFooter ? '' : (
              <div className={`px-6 py-4 flex items-center justify-center space-x-4 ${loading && 'opacity-50 pointer-events-none'}`}>
                <button
                  className='flex-1 rounded text-gray-900 text-sm font-medium bg-gray-200 hover:bg-gray-300 transition-colors px-4 py-2'
                  disabled={loading}
                  onClick={handleOnClose}
                >
                  {cancelButtonText}
                </button>
                <button
                  className='flex-1 rounded text-white text-sm font-medium bg-indigo-600 hover:bg-indigo-700 transition-colors px-4 py-2'
                  disabled={loading}
                  onClick={onAccept}
                >
                  {acceptButtonText}
                </button>
              </div>
            )}
          </Transition.Child>
        </div>
      </Transition>
    </Portal>
  )
}
