export function ModuleViewer ({ payload }) {
  return payload?.url ? (
    <div
      className="h-full"
      style={{
        backgroundImage: `url(${payload?.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: payload?.size || 'cover',
        backgroundPosition: 'center',
      }}
    ></div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Image URL missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="image-url" className="block text-sm font-medium text-gray-700">
     Image URL
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="image-url"
            id="image-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste URL..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="image-size"
          className="block text-sm font-medium text-gray-700"
        >
     Size
        </label>
        <select
          id="image-size"
          name="image-size"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.size || 'cover'}
          onChange={(e) => onUpdate('size', e.target.value)}
        >
          <option value="cover">Cover</option>
          <option value="contain">Contain</option>
        </select>
      </div>
    </div>
  )
}
