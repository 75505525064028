import { Helmet } from 'react-helmet'

export default function NotFoundPage() {
  return (
    <main
      id="404-page"
      className="h-screen flex items-center justify-center"
    >
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <div className="text-white text-center">
        <h1 className="text-4xl font-semibold">404</h1>
        <p>This page doesn't exist 😬</p>
      </div>
    </main>
  )
}
