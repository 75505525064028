export default function UserIcon ({ user, size = 'w-5 h-5' }) {

  function userImg () {
    return user?.photoURL || '/img/default-avatar.svg'
  }

  return (
    <div
      className={`${size} rounded-full border border-indigo-100 bg-indigo-800`}
      style={{
        backgroundImage: `url(${userImg()})`,
        backgroundSize: 'cover',
      }}
    ></div>
  )
}
