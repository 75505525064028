import { useState, useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db, getAuthUser } from '../../firebase'
import { useParams } from 'react-router-dom'

export function useIsScreenOwner() {
  const [isScreenOwner, setIsScreenOwner] = useState(null)
  const { screenId } = useParams()

  const handleCheckIsScreenOwnwer = async () => {
    const currentUser = await getAuthUser()
    if(!currentUser) return setIsScreenOwner(false)
    

    const docRef = doc(db, 'screens', screenId)
    const screenDoc = await getDoc(docRef)

    // Check if doc exists and if it has been claimed by the current user
    if(!screenDoc.exists || !screenDoc.data().uid) return setIsScreenOwner(false)

    if(screenDoc.data().uid === currentUser.uid) return setIsScreenOwner(true)

    setIsScreenOwner(false)
  }

  useEffect(() => {
    handleCheckIsScreenOwnwer()
  })

  if (isScreenOwner === null) {
    return null
  }

  return isScreenOwner ? true : false
}