let resolveMe = null
const awaitMe = new Promise(resolve => resolveMe = resolve)
let initDone = false

const isSandbox = process.env.NODE_ENV === 'development'

export default class PaddleLoader {
  static async getInstance () {
    PaddleLoader._loadPaddleScript()
    await awaitMe
    if (typeof window.Paddle !== 'undefined') return window.Paddle
    else throw new Error ('Error loading Paddle')
  }

  // Idempotent function
  static _loadPaddleScript () {
    let scriptElement = document.getElementById('script-paddle-js')
    if (!scriptElement) {
      scriptElement = document.createElement('script')
      scriptElement.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js')
      scriptElement.setAttribute('type', 'text/javascript')
      scriptElement.setAttribute('id', 'script-paddle-js')
      scriptElement.setAttribute('data-injected', 'true')
      document.body.appendChild(scriptElement)
    }
    if (typeof window.Paddle !== 'undefined') PaddleLoader._initPaddle()
    else scriptElement.addEventListener('load', () => { PaddleLoader._initPaddle() })
  }

  // Idempotent function
  static _initPaddle () {
    if (!initDone) {
      let vendor = parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID)
      if (isSandbox) {
        vendor = parseInt(process.env.REACT_APP_PADDLE_SANDBOX_VENDOR_ID)
        window.Paddle.Environment.set('sandbox')
      }

      if (vendor) {
        window.Paddle.Setup({
          vendor,
          completeDetails: true,
        })
      }
      initDone = true
    }
    resolveMe()
  }
}
