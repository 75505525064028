import React, { useEffect, useState } from 'react'
import { Chart } from 'react-charts'


export function ModuleViewer({ payload }) {
  const [count, setCount] = useState(11)
  const [initialData, setInitialData] = useState([
    {
      date: 1,
      stars: 1,
    },
    {
      date: 2,
      stars: 2,
    },
    {
      date: 3,
      stars: 3,
    },
    {
      date: 4,
      stars: 4,
    },
    {
      date: 5,
      stars: 5,
    },
    {
      date: 6,
      stars: 6,
    },
    {
      date: 7,
      stars: 7,
    },
    {
      date: 8,
      stars: 8,
    },
    {
      date: 9,
      stars: 9,
    },
    {
      date: 10,
      stars: 10,
    },
    {
      date: 11,
      stars: 11,
    },
  ])

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.date,
    }),
    [],
  )

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.stars,
        elementType: 'area',
      },
    ],
    [],
  )

  const handleRandomise = () => {
    let c = [...initialData]
    let d = [c[1], c[2], c[3], c[4], c[5], c[6], c[7], c[8], c[9]]
    d.push({
      date: count,
      stars: Math.floor(Math.random() * 3) + (count / 2),
    })
    return setInitialData([...d])
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleRandomise()
  }, [count])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    let myIntervalID = setInterval(() => {
      setCount((prevCount) => prevCount + 1)
    }, 1000)

    return () => clearInterval(myIntervalID)
  }, [])

  return (
    <div className='h-full bg-gray-900'>
      <div className='mx-3 py-1 w-100 h-full '>
        <Chart
          className='rounded'
          options={{
            data: [{ label: 'Data', data: initialData }],
            primaryAxis,
            secondaryAxes,

          }}
        />
      </div>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  // function onUpdate(key, value) {
  //   const newPayload = { ...payload, [key]: value }
  //   onUpdatePayload(newPayload)
  // }

  return (
    <div className="space-y-3">
      {/* <div>
        <label htmlFor="slido-url" className="block text-sm font-medium text-gray-700">
         No settings to configure... yet.
        </label>
      </div> */}
    </div>
  )
}
