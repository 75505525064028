import React, { useEffect, useState } from 'react'
import { getAuthUser } from '../../firebase'
import ProfileLayout from './ProfileLayout'
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore'
import { db } from '../../firebase'
import Spinner from '../util/Spinner'
import ScreensPreview from './ScreensPreview'
import { Link } from 'react-router-dom'

export default function Dashboards() {
  const [screens, setScreens] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)


  const getUserScreens = async () => {
    const currentUser = await getAuthUser()

    const screensRef = collection(db, 'screens')
    const q = query(screensRef, where('uid', '==', currentUser.uid), orderBy('updated', 'desc'))

    const querySnapshot = await getDocs(q)
    let data = []

    if(querySnapshot.empty){
      setLoading(false)
      return setError('No dashboards...yet.')
    }

    querySnapshot.forEach((doc) => {
      data.push({...doc.data(), id: doc.id})
    })

    setLoading(false)
    return setScreens(data)
  }

  useEffect(() => {
    getUserScreens()
  }, [])

  return (
    <ProfileLayout title="Dashboards">
      <div className='flex justify-between items-center gap-4 mb-4'>
        <h2 className='font-semibold text-2xl'>My dashboards</h2>
        <Link to={'/s'} className='rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 transition-colors px-4 py-2'>
          Create new dashboard
        </Link>
      </div>
      {loading &&
        <div className="w-8 my-8">
          <Spinner />
        </div>
      }
      {error &&
        <span className='text-sm text-gray-300'>
          {error}
        </span>
      }
      <div className='mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
        {screens.length > 0 && screens.map((screen) => {
          return (
            <ScreensPreview key={screen.id} screen={screen} />
          )
        })}
      </div>

    </ProfileLayout>
  )
}
