import { firebaseConfig, getAuthUser } from '../../firebase.js'
import 'firebaseui/dist/firebaseui.css'
import { useState, useEffect } from 'react'
import Spinner from '../util/Spinner.js'

export default function AuthUi ({ onAuth }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    initFirebaseAuth()
  })

  async function initFirebaseAuth () {
    // Check first if user is already authenticated
    const user = await getAuthUser()
    if (user) return onAuth(user)

    // Dynamic imports, so we only load the heavy unoptimized compat code here
    const { default: Firebase } = await import('firebase/compat/app')
    await import('firebase/compat/auth')
    const FirebaseUI = await import('firebaseui')

    // Unfortunately, we can't use the existing firebase v9 modular instance
    Firebase.initializeApp(firebaseConfig)

    // Configuration for the auth UI
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
      tosUrl: '/terms-of-service/',
      privacyPolicyUrl: '/privacy-policy/',
      callbacks: {
        uiShown: () => {
          setLoading(false)
        },
        signInSuccessWithAuthResult: (authResult) => {
          onAuth(authResult.user)
        },
        signInFailure: (error) => {
          console.error(error)
        },
      },
    }

    // Reuse the FirebaseUi instance if one exists to avoid errors
    let ui = FirebaseUI.auth.AuthUI.getInstance()
    if (ui) {
      ui.reset()
    } else {
      ui = new FirebaseUI.auth.AuthUI(Firebase.auth())
    }

    // Mount the auth UI element
    ui.start('#firebaseui-auth-container', uiConfig)
  }

  return (
    <div>
      {loading ? (
        <div className="w-8 mx-auto my-8">
          <Spinner />
        </div>
      ) : ''}
      <div id="firebaseui-auth-container"></div>
    </div>
  )
}
