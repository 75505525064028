// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-loading-and-initialization

let addScriptPromise = null

export async function parseTwitterWidget (element = undefined) {
  try {
    const twttr = await loadTwitterScript()
    await twttr.widgets.load(element)
    await new Promise(resolve => setTimeout(resolve, 600))
  } catch (err) {
    console.error(err.message)
  }
}

export async function loadTwitterScript () {
  if (window.twttr) Promise.resolve(window.twttr)
  return await addPlatformScript('//platform.twitter.com/widgets.js')
}

/** Adds proviced script to the page, once **/
function addPlatformScript (src) {
  if (!addScriptPromise) {
    const element = document.createElement('script')
    element.setAttribute('src', src)
    document.body.appendChild(element)
    addScriptPromise = new Promise(resolve => {
      element.onload = () => {
        resolve(window.twttr)
      }
    })
  }
  return addScriptPromise
}
