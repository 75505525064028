const colsClassMap = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
  13: 'grid-cols-13',
  14: 'grid-cols-14',
  15: 'grid-cols-15',
  16: 'grid-cols-[repeat(16,1fr)]',
}
const rowsClassMap = {
  1: 'grid-rows-1',
  2: 'grid-rows-2',
  3: 'grid-rows-3',
  4: 'grid-rows-4',
  5: 'grid-rows-5',
  6: 'grid-rows-6',
  7: 'grid-rows-7',
  8: 'grid-rows-8',
  9: 'grid-rows-[repeat(9,1fr)]',
}

export default function LayoutBase({ children, cols, rows }) {
  return (
    <div
      className={`grid border-2 border-gray-900 h-full bg-gray-900 ${colsClassMap[cols]} ${rowsClassMap[rows]}`}
    >
      {children}
    </div>
  )
}
