export function ModuleViewer({ payload }) {
  return (
    <iframe
      title="Frame"
      width="100%"
      height="100%"
      src={`http://localhost:${payload?.port}/${
        payload?.companionPage || 'emulators'
      }`}
      frameBorder="0"
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <span className="p-2 text-sm bg-gray-600 block text-gray-200 rounded">
        <span className="font-semibold">Heads up:</span> Companion must be running
    on this computer.
      </span>
      <div className="mt-2">
        <label
          htmlFor="companion-port"
          className="block text-sm font-medium text-gray-700"
        >
     Companion Port
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="companion-port"
            id="companion-port"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter Companion Port..."
            value={payload?.port || ''}
            onChange={(e) => onUpdate('port', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="page" className="block text-sm font-medium text-gray-700">
     Page
        </label>
        <select
          id="page"
          name="page"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.companionPage || 'emulators'}
          onChange={(e) => onUpdate('companionPage', e.target.value)}
        >
          <option value="emulator">Emulators (old)</option>
          <option value="tablet.html">Mobile buttons (old)</option>
          <option value="emulators">Emulators</option>
          <option value="tablet2.html">Tablet</option>
        </select>
      </div>
    </div>
  )
}
