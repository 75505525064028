export function ModuleViewer({ payload }) {
  let embedUrl = null

  // https://vdo.ninja/?view=5PPywDi&solo&room=MYROOM&password=myroom

  switch (payload?.embedType) {
  case 'solo': 
    embedUrl = new URL('https://vdo.ninja/?')
    embedUrl.searchParams.append('view', payload?.guestId)
    embedUrl.searchParams.append('solo', true)
    break

  default:
    // Defaults to Scene Link
    embedUrl = new URL('https://vdo.ninja/?scene')
    break
   
  }
  
  embedUrl.searchParams.append('room', payload?.roomName)
  embedUrl.searchParams.append('password', payload?.roomPassword)

  return payload?.roomName ? (
    <iframe
      title="VDO.ninja video player"
      width="100%"
      height="100%"
      src={embedUrl.href}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[VDO.ninja Room Name missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }


  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="Type" className="block text-sm font-medium text-gray-700">
     Type
        </label>
        <select
          id="Type"
          name="Type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.embedType || 'scene'}
          onChange={(e) => onUpdate('embedType', e.target.value)}
        >
          <option value="scene">Scene</option>
          <option value="solo">Solo</option>
        </select>
      </div>
      <div>
        <label htmlFor="room-name" className="block text-sm font-medium text-gray-700">
          Room name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="room-name"
            id="room-name"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="ABCD1234"
            value={payload?.roomName}
            onChange={(e) => onUpdate('roomName', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="room-password" className="block text-sm font-medium text-gray-700">
          Room password
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="room-password"
            id="room-password"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="ABCD1234"
            value={payload?.roomPassword}
            onChange={(e) => onUpdate('roomPassword', e.target.value)}
          />
        </div>
      </div>
      {payload?.embedType === 'solo' && <div>
        <label htmlFor="guest-id" className="block text-sm font-medium text-gray-700">
          Guest ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="guest-id"
            id="guest-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="ABCD1234"
            value={payload?.guestId}
            onChange={(e) => onUpdate('guestId', e.target.value)}
          />
        </div>
      </div>}
     
      {/* <div>
        <label htmlFor="iframe-reload">
          <input
            id="iframe-reload"
            name="iframe-reload"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.autoplay || false}
            onChange={(e) => onUpdate('autoplay', e.target.checked)}
          />
          <span>Autoplay (muted)</span>
        </label>
      </div> */}
    </div>
  )
}
