import { useRef } from 'react'
import QRCode from 'react-qr-code'
import { convertSvgToCanvas } from '../../utils/svgImageConverter.js'

export function ModuleViewer ({ payload }) {
  const svgRef = useRef()

  async function downloadQrCode () {
    const canvas = await convertSvgToCanvas(svgRef?.current, 450, 450)
    const link = document.createElement('a')
    link.download = 'qr.png'
    link.href = canvas.toDataURL('image/png')
    link.click()
  }

  return payload?.url ? (
    <div
      className="flex flex-col items-center justify-center h-full p-8"
    >
      <div className="relative group">
        <div className=" p-5 rounded-lg bg-white group-hover:opacity-30 transition-opacity">
          <QRCode
            ref={svgRef}
            value={payload?.url}
            size={150}
          />
        </div>
        <div className="absolute inset-0 flex items-center justify-center space-x-3 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 transition">
          <button
            className="px-4 py-2 text-xs font-medium rounded shadow-sm text-gray-900 bg-gray-200 hover:bg-gray-300 transition-colors"
            onClick={downloadQrCode}
          >
            Download
          </button>
          {payload?.url.startsWith('http') ? <a
            className="px-4 py-2 text-xs font-medium rounded shadow-sm text-gray-900 bg-gray-200 hover:bg-gray-300 transition-colors"
            href={payload?.url}
            target="_blank"
            rel="noreferrer"
          >
            Visit
          </a> : ''}
        </div>
      </div>
      <span className="pt-4 font-semibold text-xl">{payload?.text}</span>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[No URL]</span>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="url-value" className="block text-sm font-medium text-gray-700">
          URL
        </label>
        <div className="mt-1">
          <input
            type="url"
            name="url-value"
            id="url-value"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter a url..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="text-value" className="block text-sm font-medium text-gray-700">
          Text
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="text-value"
            id="text-value"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter text..."
            value={payload?.text || ''}
            onChange={(e) => onUpdate('text', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
