export function ModuleViewer ({ payload }) {
  let videoUrl = ''

  switch (payload?.embedType) {
  case 'live-chat':
    videoUrl = new URL(`${payload?.videoId}/chat`, 'https://vimeo.com/event/')
    break
    
  case 'live-event':
    videoUrl = new URL(`${payload?.videoId}/embed`, 'https://vimeo.com/event/')
    break
    
  default:
    videoUrl = new URL(payload?.videoId, 'https://player.vimeo.com/video/')
    videoUrl.searchParams.append('byline', '0')
    videoUrl.searchParams.append('autopause', '0')
    break
  }

  return payload?.videoId ? (
    <iframe
      title="Vimeo video player"
      width="100%"
      height="100%"
      src={videoUrl}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Vimeo video URL missing]</span>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  const videoId = {
    get: () => payload?.videoId && payload.videoId.length > 8 ? `https://vimeo.com/${payload.videoId}` : payload?.videoId || '',
    set: (value) => {
      const regex = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|event\/|watch\?\S*v=|v\/)?(?<id>\d*)/
      const matches = value.match(regex)
      onUpdate('videoId', matches?.[1] || value)
    },
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="Type" className="block text-sm font-medium text-gray-700">
     Type
        </label>
        <select
          id="Type"
          name="Type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.embedType || 'video'}
          onChange={(e) => onUpdate('embedType', e.target.value)}
        >
          <option value="video">Video</option>
          <option value="live-event">Live event</option>
          <option value="live-chat">Chat</option>
        </select>
      </div>
      <div>
        <label htmlFor="vimeo-url" className="block text-sm font-medium text-gray-700">
        Vimeo URL, Video ID or Event ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="vimeo-url"
            id="vimeo-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://vimeo.com/01234567"
            value={videoId.get()}
            onChange={(e) => videoId.set(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
