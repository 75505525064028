import React, { useEffect, useState } from 'react'
import ModalWrapper from './ModalWrapper'
import { tiles } from '../modules/moduleTypes'

export default function ModalCustomLayout ({
  show,
  heading = 'Custom layout',
  body='Click and drag to create your own custom boxes.',
  closable,
  onClose,
  onAccept,
  acceptButtonText = 'Done',
  loading,
  screen,
}) {
  const [layout, setLayout] = useState(screen.customLayout || [])
  const [drawing, setDrawing] = useState(false)
  const [drawingStart, setDrawingStart] = useState(null)
  const [highlight, setHighlight] = useState([])


  // X axis = COLS
  // Y axis = ROWS
  // 0,0 is actually 1,1
  // 1,1 starts from top left

  const handleOnMouseDown = (coords) => {
    setDrawing(true)
    setDrawingStart(coords)
  }

  const handleOnMouseUp = (coords) => {

    let colStart = drawingStart.x
    let colEnd = coords.x
    let colSpan =  (coords.x - drawingStart.x) + 1
    let rowStart = drawingStart.y
    let rowEnd = coords.y
    let rowSpan =  (coords.y - drawingStart.y) + 1

    handleAddToCustomLayout({
      colStart,
      colEnd,
      colSpan,
      rowStart,
      rowEnd,
      rowSpan,
      styles: ` col-start-${colStart}  col-span-${colSpan}  row-start-${rowStart}  row-span-${rowSpan} `,
    })

    setDrawing(false)
    setDrawingStart(null)
    return setHighlight([])
  }

  const handleOnMouseMove = (coords) => {
    if(!drawing) return null

    let colStart = drawingStart.x
    let colEnd =  coords.x
    let rowStart = drawingStart.y
    let rowEnd =  coords.y

    setHighlight({colStart, colEnd, rowStart, rowEnd})
  }

  const handleAddToCustomLayout = (newItem) => {
    return setLayout([
      ...layout,
      newItem,
    ])
  }

  const handleRearrangeCustomLayout = (oldIndex, newIndex) => {
    const newArray = Array.from(layout)

    newArray.splice(oldIndex, 1, newArray.splice(newIndex, 1, newArray[oldIndex])[0])

    setLayout(newArray)
  }

  const handleRemoveCustomBox = (id) => {
    const newArray = Array.from(layout)
    let filtered = newArray.filter((_, i)=> id !== i)

    return setLayout(filtered)
  }

  const handleSaveCustomLayout = () => {

    return onAccept(layout)
  }

  useEffect(()=>{
    if(screen.customLayout?.length) {
      setLayout(screen.customLayout)
    }
  }, [screen.customLayout])

  return (
    <ModalWrapper
      show={show}
      heading={heading}
      closeable={closable}
      onClose={onClose}
      onAccept={handleSaveCustomLayout}
      acceptButtonText={acceptButtonText}
      loading={loading}
    >
      <span>{body}</span>

      <div className='relative w-[32rem] grid gap-1 mx-auto grid-cols-[repeat(16,_minmax(0,_1fr))] grid-rows-[repeat(9,_minmax(0,_1fr))]'>
        {Array.from(Array(9).keys()).map((_, idx)=>{
          const y = idx + 1
          return (
            Array.from(Array(16).keys()).map((id)=>{
              const x = id + 1
              let drawingStyles
              if((x >= highlight.colStart && x <= highlight.colEnd) && (y >= highlight.rowStart && y <= highlight.rowEnd)) drawingStyles = 'bg-gray-500'
              return (
                <button
                  key={id}
                  onMouseDown={()=> handleOnMouseDown({x, y})}
                  onMouseUp={()=> handleOnMouseUp({x, y})}
                  onMouseEnter={()=> handleOnMouseMove({x, y})}
                  className={'w-7 h-7 inline-block text-xs select-none border border-gray-300 hover:border-2 hover:border-gray-900 transition-all rounded ' + drawingStyles}>
                  {/* {x} */}
                  {/* {y} */}
                </button>
              )
            }))
        })}

        <div className='absolute top-0 left-0 w-[32rem] h-full pointer-events-none grid gap-1 mx-auto grid-cols-[repeat(16,_minmax(0,_1fr))] grid-rows-[repeat(9,_minmax(0,_1fr))]'>
          {layout.map((box, boxIndex)=>{
            return (
              <span
                key={boxIndex}
                style={{
                  backgroundColor: tiles[screen?.modules?.[boxIndex]?.type]?.color || '#374151', // "backup option: bg-gray-700", 
                }}
                className={[
                  'text-xs text-white border border-gray-300 pointer-events-auto flex text-center items-center justify-center rounded',
                  box.rowSpan > 1 ? 'flex-col  space-y-1' : 'flex-row  space-x-1',
                  box.styles,
                ].join(' ')}
              >
                {(box.colSpan > 1 || box.rowSpan > 1) && 
                <>
                  <select
                    value={boxIndex}
                    className='text-black rounded-sm focus:outline-none focus:ring'
                    onChange={(e) => handleRearrangeCustomLayout(boxIndex, parseInt(e.target.value))}
                  >
                    {
                      layout.map((_box, _index)=>{
                        return <option key={`${boxIndex}_${_index}`} value={_index}>Box {_index + 1}</option>
                      })
                    }
                  </select>
                </>
                }
                <button className='p-0.5 bg-gray-800 hover:bg-gray-600 transition rounded' onClick={()=>handleRemoveCustomBox(boxIndex)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                  </svg>
                </button>
              </span>
            )
          })}
        </div>
      </div>
    </ModalWrapper>
  )
}
