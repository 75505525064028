import { categories, tiles } from './moduleTypes.js'
import UpgradeModal from '../modals/ModalUpgrade.js'
import { useState } from 'react'
import {useIsPaidUser} from '../util/useIsPaidUser.js'

export default function ModuleEditorTiles({ onSelect }) {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const isPaidUser = useIsPaidUser()

  return categories.map((category) => (
    <section key={category.heading} className="py-4">
      <header className="flex items-center gap-2 text-gray-400 mb-4">
        <h2 className="text-xs uppercase font-semibold">
          {category.heading}
        </h2>
        <span className="flex-1 h-px bg-gray-300" />
      </header>
      <ul className="flex flex-wrap items-center justify-start gap-4">
        {category.modules.filter(type => tiles[type] && !tiles[type].hide).map((type) => (
          <li className="relative text-center w-32" key={type}>
            {(tiles[type].paid && !isPaidUser) && 
            <span className='absolute top-1 right-1 px-1 py-0.5 cursor-default pointer-events-none flex space-x-1 bg-gray-500 text-white rounded text-xs pro-gradient'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
              </svg>
              <span className='font-semibold'>Pro</span>
            </span>}
            <button
              className="rounded-lg shadow ring ring-transparent hover:ring-blue-500 transition w-32 h-20 disabled:pointer-events-none"
              style={{
                backgroundColor: tiles[type].color,
                backgroundImage: `url(${tiles[type].thumb})`,
                backgroundSize: 'cover',
              }}
              onClick={() => {
                // Checks if the module is paid and the user is paying
                if(tiles[type].paid && !isPaidUser) return setShowUpgradeModal(true)

                return onSelect(type)
              }}
            >
              <img
                className="inline-block max-w-[36px] max-h-[24px] w-full"
                src={tiles[type].icon}
                alt=""
              />
            </button>
            <div className="text-sm font-semibold text-gray-600">
              {tiles[type].label}
            </div>
          </li>
        ))}
      </ul>
      <UpgradeModal show={showUpgradeModal} onClose={()=>setShowUpgradeModal(false)} body='This module is available on our paid subscription.' />
    </section>
  ))
}
