export function ModuleViewer({ payload }) {
  return (
    <iframe
      title="Stagetimer Module"
      width="100%"
      height="100%"
      src={`https://stagetimer.io/r/${payload?.roomId}/${payload?.page || ''}`}
      frameBorder="0"
      allow="picture-in-picture; screen-wake-lock"
      allowFullScreen
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div className="mt-2">
        <label
          htmlFor="room-id"
          className="block text-sm font-medium text-gray-700"
        >
     Stagetimer Room ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="room-id"
            id="room-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste Room ID..."
            value={payload?.roomId || ''}
            onChange={(e) => onUpdate('roomId', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="page" className="block text-sm font-medium text-gray-700">
     Page
        </label>
        <select
          id="page"
          name="page"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.page || ''}
          onChange={(e) => onUpdate('page', e.target.value)}
        >
          <option value="">Viewer</option>
          <option value="controller">Controller</option>
          <option value="agenda">Agenda</option>
          <option value="moderator">Moderator</option>
        </select>
      </div>
    </div>
  )
}
