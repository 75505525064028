import ProfileLayout from './ProfileLayout'
import Spinner from '../util/Spinner.js'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PaddleLoader from '../../utils/PaddleLoader.js'
import { useState, useEffect } from 'react'
import { getAuthUser } from '../../firebase.js'

export default function Login () {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const planId = searchParams.get('subscription_plan_id')
    if (planId) initCheckout(planId)
    else navigate('/dashboards')
  })

  async function initCheckout (planId) {
    const user = await getAuthUser()
    const paddle = await PaddleLoader.getInstance()

    // Trigger Paddle checkout
    paddle.Checkout.open({
      product: planId,
      email: user.email,
      allowQuantity: false,
      passthrough: user.uid,
      loadCallback: () => {
        setLoading(false)
      },
      successCallback: (data) => {
        const query = new URLSearchParams({ 'plan_id': planId, 'plan_name': data.product.name })
        navigate(`/checkout-success?${query}`, { replace: true })
      },
      closeCallback: (data) => {
        navigate('/dashboards')
      },
    })
  }

  return (
    <ProfileLayout title="Checkout">
      <div className="py-24">
        {loading ? (
          <div className="flex items-center justify-center gap-4">
            <div className="w-10">
              <Spinner/>
            </div>
            <div className="text-gray-400 font-semibold text-xl">
              Initiate Checkout ...
            </div>
          </div>
        ) : ''}
      </div>
    </ProfileLayout>
  )
}
