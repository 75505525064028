export function ModuleViewer({ payload }) {
  let embedUrl = null

  switch (payload?.embedType) {
  case 'view':
    embedUrl = new URL(`${payload?.sourceId}/preview`, 'https://docs.google.com/document/d/')
    break

  default:
    embedUrl = new URL(`${payload?.sourceId}/edit`, 'https://docs.google.com/document/d/')
    break
  }
  

  return payload?.sourceId ? (
    <iframe
      title="Google Doc viewer"
      width="100%"
      height="100%"
      src={embedUrl.href}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Google Doc ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  const docId = {
    get: () =>
      payload?.sourceId || '',
    set: (value) => {
      const matches = value.split('/edit')
      onUpdate('sourceId', matches?.[0] || value)
    },
  }


  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="Type" className="block text-sm font-medium text-gray-700">
     Display mode
        </label>
        <select
          id="Type"
          name="Type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.embedType || 'edit'}
          onChange={(e) => onUpdate('embedType', e.target.value)}
        >
          <option value="edit">Edit</option>
          <option value="view">View-only</option>
        </select>
      </div>
      <div>
        <label htmlFor="google-doc-source-id" className="block text-sm font-medium text-gray-700">
          Google Doc URL
        </label>
        <span className="text-xs text-gray-500">Note: Doc must be shared with 'Anyone with the link'.</span>
        <div className="mt-1">
          <input
            type="text"
            name="google-doc-source-id"
            id="google-doc-source-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://docs.google.com/document/d/..."
            value={docId.get()}
            onChange={(e) => docId.set(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
