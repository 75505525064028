export default function ModuleTypePlaceholder({ onEdit, disabled = false }) {
  return (
    <button
      className={`
        block h-full w-full cursor-pointer rounded-md border-4 border-dashed text-gray-700 border-current bg-gray-900 transition-colors
        ${disabled ? 'cursor-default' : 'hover:text-gray-500'}
      `}
      disabled={disabled}
      onClick={onEdit}
    >
      {disabled ? '' : (
        <span className="text-xl font-semibold">
          Add
        </span>
      )}
    </button>
  )
}
