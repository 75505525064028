export function ModuleViewer({ payload }) {
  let embedUrl = null

  switch (payload?.embedType) {
  case 'channel':
    embedUrl = new URL('https://player.twitch.tv/?')
    embedUrl.searchParams.append('channel', payload?.sourceId)
    break

  case 'video':
    embedUrl = new URL('https://player.twitch.tv/?')
    embedUrl.searchParams.append('video', payload?.sourceId)
    break

  case 'chat':
    embedUrl = new URL(`https://www.twitch.tv/embed/${payload?.sourceId}/chat`)
    break

  default:
    embedUrl = new URL('https://player.twitch.tv/?')
    embedUrl.searchParams.append('channel', payload?.sourceId)
    break
  }

  embedUrl.searchParams.append('parent', window.location.hostname)

  return payload?.sourceId ? (
    <iframe
      title="Twich video player"
      width="100%"
      height="100%"
      src={embedUrl.href}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Twitch Video ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }


  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="Type" className="block text-sm font-medium text-gray-700">
          Type
        </label>
        <select
          id="Type"
          name="Type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.embedType || 'channel'}
          onChange={(e) => onUpdate('embedType', e.target.value)}
        >
          <option value="channel">Channel</option>
          <option value="video">Video</option>
          <option value="chat">Chat</option>
        </select>
      </div>
      <div>
        <label htmlFor="twitch-source-id" className="block text-sm font-medium text-gray-700">
          {(!payload?.embedType || payload?.embedType === 'channel')  && 'Twitch channel name' }
          {payload?.embedType === 'video' && 'Video ID'}
          {payload?.embedType === 'chat' && 'Twitch channel name'}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="twitch-source-id"
            id="twitch-source-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="ABCD1234"
            value={payload?.sourceId}
            onChange={(e) => onUpdate('sourceId', e.target.value)}
          />
        </div>
      </div>
      {/* <div>
        <label htmlFor="iframe-reload">
          <input
            id="iframe-reload"
            name="iframe-reload"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.autoplay || false}
            onChange={(e) => onUpdate('autoplay', e.target.checked)}
          />
          <span>Autoplay (muted)</span>
        </label>
      </div> */}
    </div>
  )
}
