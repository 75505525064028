import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'
import Webcam from 'react-webcam'


export function ModuleViewer({ payload }) {
  const [webcams, setWebcams] = useState([])
  const [selectedWebcam, setSelectedWebcam] = useState(null)
  const [hover, setHover] = useState(false)

  const handleWebcamChange = (event) => {
    setSelectedWebcam(event.target.value)
  }

  const handleGetWebcams = async () =>{
    const mediaDevices = navigator.mediaDevices
    const availableWebcams = await mediaDevices.enumerateDevices()
    const availableWebcamOptions = availableWebcams.filter(device => device.kind === 'videoinput')
    return setWebcams(availableWebcamOptions)
  }

  useEffect(() => {
    async function getWebcams() {
      const constraints = { video: true, audio: true }

      // CHROME permissions check
      if(navigator.getUserMedia) {
        navigator.getUserMedia(
          constraints,
          function() {
            return handleGetWebcams()
          },
          function(err) {
            console.error(err)
          }
        )

        // ALTERNATE CHECK FOR OTHER BROWSERS
      } else {
        await navigator.mediaDevices.getUserMedia(constraints)
        return handleGetWebcams()
      }
    }

    getWebcams()
  }, [])

  return (
    <div className="relative w-full h-full aspect-video">
      <div  onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}  className='flex justify-center items-center h-full'>
        {selectedWebcam ? (
          <div className='relative flex justify-center items-center h-full'>
            <Transition
              className="absolute z-10 top-3 right-3"
              show={hover}
              enter="transition"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button onClick={() => setSelectedWebcam(null)} className='py-2 px-3 bg-gray-700 hover:bg-gray-600 rounded pointer-events-auto'>Switch webcam</button>
            </Transition>

            <Webcam className='flex justify-center items-center max-h-full w-full h-full bg-black' audio={payload?.audio || false} height={720} width={1280} videoConstraints={{ deviceId: selectedWebcam }}  />
          </div>
        ) : 
          <div className='flex flex-col space-y-1'>
            <p>Select a webcam</p>
            <select 
              onChange={handleWebcamChange} 
              className='px-2 h-8 shadow-sm bg-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'>
              <option value={''}>Choose...</option>
              {webcams.map(webcam => {
                return (
                  <option key={webcam.deviceId} value={webcam.deviceId}>{webcam.label}</option>
                )
              })}
            </select>
          </div>
        }
      </div>
    </div>
  ) 
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <span className="p-2 mt-2 text-sm bg-gray-600 block text-gray-200 rounded">
        <span className="font-semibold">Heads up:</span> This won't stream your webcam, it's made for a local viewing only.
      </span>
      <div className='mt-2'>
        <label htmlFor="webcam-audio">
          <input
            id="webcam-audio"
            name="webcam-audio"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.audio || false}
            onChange={(e) => onUpdate('audio', e.target.checked)}
          />
          <span>Allow webcam audio</span>
        </label>
      </div>
    </div>
  )
}