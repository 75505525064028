import { useState, useEffect, useCallback } from 'react'
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { db, getAuthUser } from '../../firebase'

const isActive = (payload) => {
  const now = new Date()

  const active = ['active', 'trialing'].includes(payload.status)
  const deadline = payload.cancellation_effective_date || payload.next_bill_date
  const isBeforeDeadline = deadline ? now < new Date(deadline) : false
  return active || isBeforeDeadline

}

export function useIsPaidUser() {
  const [isPaidUser, setIsPaidUser] = useState(null)

  const handleCheckIsPaidUser = useCallback(async () => {
    const currentUser = await getAuthUser()
    if(!currentUser) return setIsPaidUser(false)

    const subsRef = await collection(db, 'subscriptions')
    const q = await query(subsRef, where('uid', '==', currentUser?.uid), orderBy('updated', 'desc'), limit(1))


    const querySnapshot = await getDocs(q)

    if(querySnapshot.empty) return setIsPaidUser(false)

    const payload = querySnapshot?.docs?.[0].data()

    const isA = await isActive(payload)

    return await setIsPaidUser(isA)
  }, [])

  useEffect(() => {
    handleCheckIsPaidUser()
  }, [handleCheckIsPaidUser])

  if (isPaidUser === null) {
    return null
  }

  return isPaidUser ? true : false
}
