import UpgradeButton from '../util/UpgradeButton.js'
import { useIsPaidUser } from '../util/useIsPaidUser.js'

export function ModuleViewer () {
  const isPaidUser = useIsPaidUser()
  return (
    <div
      className="flex flex-col items-center justify-center text-center h-full p-8 space-y-4"
    >
      <p className="text-gray-200 font-semibold text-2xl">Oh hey 👋</p>
      <p className="text-gray-300 font-semibold">Welcome to Dashmaster 2k</p>
      <p className="text-gray-300 text-sm">Use the <code className='p-1 bg-gray-900 rounded'>Edit</code> button to change the content of boxes, switch layouts below</p>
      {!isPaidUser && <p className="text-gray-300 text-sm whitespace-nowrap"><UpgradeButton text='or Upgrade for even more features' /></p>}
    </div>
  )
}

export function ModuleEditor () {
  return (
    <div className="space-y-3"></div>
  )
}
