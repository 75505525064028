import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthUi from './auth/AuthUi.js'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Login () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const signupUrl = '/signup' + (!searchParams ? '' : '?' + searchParams)

  function redirectWhenAuthenticated () {
    const next = searchParams.get('next')
    if (next) return navigate(next, { replace: true })
    else navigate('/dashboards', { replace: true })
  }

  return (
    <main className="flex items-center justify-center h-screen text-white text-center">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <section>
        <h1 className="text-3xl">Login</h1>
        <AuthUi onAuth={redirectWhenAuthenticated} />
        <p className="text-gray-500 font-light text-sm">
          You don't you have an account?
          <Link to={signupUrl} className="underline ml-1">Sign up</Link>
        </p>
      </section>
    </main>
  )
}
