export function ModuleViewer() {
  return (
    <iframe
      title="Time of day Module"
      width="100%"
      height="100%"
      src='https://stagetimer.io/r/TODDEMO1/'
      frameBorder="0"
      allow="picture-in-picture"
      allowFullScreen
    />
  )
}

export function ModuleEditor() {
  return (
    <div className="space-y-3">
      {/* <span className="block text-sm font-medium text-gray-700">
        No settings to configure... yet.
      </span> */}
    </div>
  )
}
