import { useState } from 'react'
import _omit from 'lodash/omit'

// https://www.youtube.com/watch?v=l8JBJ6zultk
// https://youtu.be/l8JBJ6zultk
// https://youtu.be/l8JBJ6zultk?t=180
// https://www.youtube.com/embed/l8JBJ6zultk
// https://www.youtube-nocookie.com/embed/l8JBJ6zultk?start=180
// https://www.youtube.com/watch?v=qQUiFmDWWjI&list=PL7SGPHxyXvrA4wkN-Yp0EgKAXkyPTRg2F&index=1&t=175s

function validUrl (url) {
  const params = extractParams(url)
  return params?.id !== undefined
}
function extractParams (urlStr) {
  try {
    const url = new URL(urlStr)
    return {
      id: url.searchParams.get('v') || url.pathname.split('/').pop() || undefined,
      start: parseInt(url.searchParams.get('t')) || parseInt(url.searchParams.get('start')) || 0,
    }
  } catch (err) {
    return undefined
  }
}

export function ModuleViewer({ payload }) {
  const urlStr = payload?.videoId ? `https://youtu.be/${payload?.videoId}` : payload?.url
  const params = extractParams(urlStr)
  let videoUrl = ''

  switch (payload?.embedType) {
  case 'chat':
    videoUrl = new URL('https://www.youtube.com/live_chat')
    videoUrl.searchParams.set('v', params?.id)
    break

  default:
    videoUrl = new URL(params?.id, 'https://www.youtube-nocookie.com/embed/')
    if (params?.start) videoUrl.searchParams.set('start', params?.start)
    if (payload?.autoplay) {
      videoUrl.searchParams.set('mute', 1)
      videoUrl.searchParams.set('autoplay', 1)
    }
    break
  }

  videoUrl.searchParams.set('embed_domain', window.location.hostname)

  return params?.id ? (
    <iframe
      title={payload?.embedType === 'chat' ? 'YouTube chat' : 'YouTube video player'}
      width="100%"
      height="100%"
      src={videoUrl.href}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[YouTube Video ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  const [invalid, setInvalid] = useState([])

  if (payload?.videoId) {
    onUpdatePayload({
      ..._omit(payload, ['videoId']),
      url: `https://youtu.be/${payload?.videoId}`,
    })
  }

  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    if (key === 'url') validateUrl(value || '')
    onUpdatePayload(newPayload)
  }

  function validateUrl (url) {
    if (!url) return setInvalid([])
    if (validUrl(url)) setInvalid([])
    else setInvalid(['url'])
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="Type" className="block text-sm font-medium text-gray-700">
          Type
        </label>
        <select
          id="Type"
          name="Type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.embedType || 'video'}
          onChange={(e) => onUpdate('embedType', e.target.value)}
        >
          <option value="video">Video</option>
          <option value="chat">Chat</option>
        </select>
      </div>
      <div>
        <label htmlFor="youtube-url" className="block text-sm font-medium text-gray-700">
          Youtube URL
          {invalid.includes('url') ? <span className="text-red-500 ml-2">(Invalid URL)</span> : ''}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="youtube-url"
            id="youtube-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://youtu.be/..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="iframe-reload">
          <input
            id="iframe-reload"
            name="iframe-reload"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.autoplay || false}
            onChange={(e) => onUpdate('autoplay', e.target.checked)}
          />
          <span>Autoplay (muted)</span>
        </label>
      </div>
    </div>
  )
}
