import { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import Loading from '../util/Loading.js'

function validateUrl (url = '') {
  if (!url) return false
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i
  return regex.test(url.trim())
}

export function ModuleViewer ({ payload, onUpdatePayload }) {
  const [key, setKey] = useState(0)

  const cache_ttl = Number(payload.cache_ttl) || -1
  const full_page = Boolean(payload.full_page)

  if (cache_ttl > 0) {
    setTimeout(() => setKey(key + 1), cache_ttl)
  }

  if (payload.signedUrl) return (
    <div
      className={`h-full bg-[size:100%] bg-no-repeat ${full_page ? 'animate-vertical-scroll' : 0}`}
      style={{
        backgroundImage: `url(${payload.signedUrl})`,
        backgroundRepeat: full_page ? 'repeat-y' : 'no-repeat',
      }}
      key={key}
    ></div>
  )
  else return (
    <div className="flex flex-col justify-center items-center space-y-2 h-full">
      <span className='text-xl'>⚠️</span>
      <span className="text-lg font-semibold text-gray-600">
        [Website URL missing]
      </span>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {
  const [invalid, setInvalid] = useState([])
  const [loading, setLoading] = useState(false)

  const validate = useCallback((iPayload) => {
    const newInvalid = []
    if (iPayload.url && !validateUrl(iPayload.url)) newInvalid.push('url')
    setInvalid(newInvalid)
    return newInvalid.length === 0
  }, [setInvalid])

  // Call validate right away when component is created
  useEffect(() => { validate(payload) }, [validate, payload])

  const getSignedScreenshotUrl = useCallback(async (iPayload) => {
    try {
      const functionUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE + '/getSignedScreenshotUrl'
      const params = {
        url: iPayload.url,
        full_page: iPayload.full_page,
        cache_ttl: iPayload.cache_ttl,
      }
      const { data } = await axios.get(functionUrl, { params })
      onUpdatePayload({ ...iPayload, signedUrl: data })
      return data
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, onUpdatePayload])

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedSignedUrl = useCallback(_debounce(getSignedScreenshotUrl, 1500), [getSignedScreenshotUrl])
  /* eslint-enable react-hooks/exhaustive-deps */

  const onUpdate = useCallback(async (key, value) => {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
    const valid = validate(newPayload)
    if (valid) {
      setLoading(true)
      debouncedSignedUrl(newPayload)
    }
  }, [payload, onUpdatePayload, debouncedSignedUrl, validate])

  return (
    <div className="space-y-3">
      <div>
        <label
          htmlFor="screenshot-url"
          className="flex items-center gap-2 text-gray-700"
        >
          <span className='text-sm font-medium'>URL</span>
          {invalid.includes('url') ? <span className="text-red-500">(Invalid URL)</span> : ''}
          {loading ? <Loading className="h-4 w-4" /> : ''}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="screenshot-url"
            id="screenshot-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste URL..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="screenshot-fullpage" className="block text-sm font-medium text-gray-700">
          Full Page
        </label>
        <div className="mt-1">
          <input
            id="screenshot-fullpage"
            name="screenshot-fullpage"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.full_page || false}
            onChange={(e) => onUpdate('full_page', e.target.checked)}
          />
          <span className="text-sm">Take a screenshot of the entire page and slowly scroll through it.</span>
        </div>
      </div>
      <div>
        <label htmlFor="screenshot-cache-ttl" className="block text-sm font-medium text-gray-700">
          Refresh
        </label>
        <select
          id="screenshot-cache-ttl"
          name="screenshot-cache-ttl"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.cache_ttl || '-1'}
          onChange={(e) => onUpdate('cache_ttl', e.target.value)}
        >
          <option value="-1">Never</option>
          <option value="14400">Every 4 hours</option>
          <option value="86400">Every day</option>
        </select>
      </div>
    </div>
  )
}
