import { getAuth, updateProfile } from 'firebase/auth'
import { addDoc, collection, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { db, getAuthUser } from '../../firebase'
import ColorHash from 'color-hash'
import isToday from '../util/isToday'
import { useIsScreenOwner } from '../util/useIsScreenOwner'

const colorHash = new ColorHash({lightness: 0.3})

const MESSAGE_STATUS = {
  DELETED: 'DELETED',
}

export function ModuleViewer ({ payload }) {
  const {screenId} = useParams()
  const [text, setText] = useState('')
  const [messages, setMessages] = useState([])
  const [sendingMessage, setSendingMessage] = useState(false)
  const [user, setUser] = useState()
  const [name, setName] = useState('')

  const messagesContainerRef = useRef()
  const messageInputRef = useRef()

  const isScreenOwner = useIsScreenOwner()

  const handleGetUser = async ()=>{
    const user = await getAuthUser()
    return setUser(user)
  }

  const handleSetUsersDisplayName = async () =>{
    if(!name) return null

    const auth = await getAuth()

    await updateProfile(auth.currentUser, {
      displayName: name,
    }).then(() => {
      return
    }).catch((error) => {
      console.error(error)
    })

    return handleGetUser()
  }

  const handleAddMessage = async () => {
    if(!text || !user) return null

    setSendingMessage(true)

    const messageObject = {
      uid: user?.uid,
      author: user?.displayName,
      authorImage: user?.photoURL,
      timestamp: new Date(),
      text,
    }

    await addDoc(collection(db, 'screens', screenId, 'chat'), messageObject)

    setText('')
    setSendingMessage(false)

    // TIMEOUT added here to give the input some time to become not-disabled
    return setTimeout(()=>{messageInputRef.current?.focus()}, [100])

  }

  const handleDeleteMessage = async (docId) => {
    const docRef = doc(db, 'screens', screenId, 'chat', docId)

    await updateDoc(docRef, {
      status: MESSAGE_STATUS.DELETED,
    })
  }

  useEffect(()=>{
    if(messagesContainerRef.current) {
      messagesContainerRef.current.scrollIntoView({block: 'end', inline: 'nearest', behavior: 'auto'})
    }
  }, [messages, messagesContainerRef])

  useEffect(()=>{
    handleGetUser()
    const q = query(collection(db, 'screens', screenId, 'chat'), orderBy('timestamp'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesArray = []
      querySnapshot.forEach((doc) => {
        messagesArray.push({id: doc.id, ...doc.data()})
      })
      setMessages(messagesArray)
    })
    return ()=>{
      unsubscribe()
    }
  }, [screenId])

  if (!user) return (
    <div className="flex flex-col items-center justify-center space-y-4 h-screen text-white">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
      </svg>

      <div className="text-xl font-semibold max-w-xs text-center">
        <span><Link to={`/login?next=/s/${screenId}`} className='underline'>Log in</Link> to use the live chat.</span>
        <br />

      </div>
    </div>
  )

  // This ensures the user has added a displayName to their user profile on Firebase Auth
  if (!user?.displayName) return (
    <div className='flex items-center justify-center h-full w-100'>
      <div className='flex flex-col space-y-2'>
        <div className='mt-2'>
          <label htmlFor="display-name" className="block text-sm font-medium text-gray-200">
            Display Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="display-name"
              id="display-name"
              className="px-2 h-8 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="My name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <button disabled={!name} onClick={handleSetUsersDisplayName} className='py-2 px-3 bg-gray-700 hover:bg-gray-600 rounded disabled:pointer-events-none disabled:opacity-50'>Start chatting</button>
      </div>
    </div>
  )

  return (
    <div className="h-full flex flex-col">
      <p className="py-2 text-center text-gray-300 bg-gray-800 border-b-4 border-gray-900">Live chat</p>
      <div className="h-full overflow-scroll">
        <ul ref={messagesContainerRef} className='p-4 flex flex-col space-y-4 overflow-y-scroll'>
          {messages.map((message)=>{
            if(message.status === MESSAGE_STATUS.DELETED) return null

            let ts = new Date(message.timestamp?.seconds * 1000)
            const tsDate = isToday(ts) ? 'Today' :  ts.toLocaleDateString(
              navigator.language || 'en-US',
              {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }
            )
            const tsTime =  ts.toLocaleTimeString(navigator.language || 'en-US', { hour: '2-digit', minute: '2-digit' })

            return (
              <li
                key={message.id}
                className="px-3 py-3 flex items-center space-x-4 rounded-lg rounded-br-sm bg-gray-900 text-white group"
              >
                <span className='flex items-center'>
                  <span
                    style={{
                      backgroundColor: colorHash.hex(message.author || 'Hello world'),
                      backgroundImage: `url(${message.authorImage})`,
                      backgroundSize: 'contain',
                    }}
                    className='w-10 h-10 flex items-center justify-center rounded-full'
                  >
                    {!message.authorImage && <span className='font-semibold'>{message.author?.[0] || ''}</span>}
                  </span>
                </span>
                <span className='flex flex-col w-full'>
                  <p className='text-xs text-gray-500'>
                    <span className='font-semibold'>{message.author}</span><span> • {tsDate} at {tsTime}</span></p>
                  <p>{message.text}</p>
                </span>
                {isScreenOwner &&
                <button
                  className='p-2 opacity-0 group-hover:opacity-100 transition-all text-red-500 focus:ring hover:bg-gray-800 rounded'
                  onClick={()=> handleDeleteMessage(message.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </button>}
              </li>
            )
          })}
        </ul>
      </div>
      <span className='px-2 pt-1 text-xs'>Chatting as {user?.displayName}</span>
      <div className="p-1">
        <div className="mt-auto flex rounded bg-gray-900 text-white">
          <input
            ref={messageInputRef}
            type='text'
            autoComplete='new-password' // Removes 1Password auto-complete
            placeholder="Write your message here..."
            value={text}
            onChange={(e)=>setText(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key === 'Enter') {
                return handleAddMessage()
              }
            }}
            disabled={sendingMessage}
            className="px-4 py-4 w-full bg-gray-900 rounded-l focus:outline-none placeholder:text-gray-500 disabled:opacity-70"
          />
          <button
            className="p-4 bg-gray-700 rounded-r focus:outline-none focus:bg-gray-600 hover:bg-gray-600 transition-all disabled:pointer-events-none disabled:opacity-70"
            onClick={handleAddMessage}
            disabled={sendingMessage || !text}
          >
            Send
          </button>

        </div>
      </div>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {

  // function onUpdate (key, value) {
  //   const newPayload = { ...payload, [key]: value }
  //   onUpdatePayload(newPayload)
  // }

  return (
    <div className="space-y-3">
      <span className="text-xs text-gray-500">Note: Users must log in to their Dashmaster 2k account to see the live chat.</span>
      {/* <div>
        <label htmlFor="text-value" className="block text-sm font-medium text-gray-700">
          Text
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="text-value"
            id="text-value"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter text..."
            value={payload?.text || ''}
            onChange={(e) => onUpdate('text', e.target.value)}
          />
        </div>
      </div> */}
    </div>
  )
}
