export default function getContrastingColor (bgColor) {
  // Convert the hex code to RGB values
  const bgColorRgb = {
    r: parseInt(bgColor.slice(1, 3), 16),
    g: parseInt(bgColor.slice(3, 5), 16),
    b: parseInt(bgColor.slice(5, 7), 16),
  }

  // Calculate the luminance value of the background color
  const luminance = (0.299 * bgColorRgb.r + 0.587 * bgColorRgb.g + 0.114 * bgColorRgb.b) / 255

  // If the luminance is greater than 0.5, use black text; otherwise, use white text
  return luminance > 0.5 ? '#000000' : '#ffffff'
}
