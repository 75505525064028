export function ModuleViewer({ payload }) {
  return (
    <iframe
      title="Frame"
      width="100%"
      height="100%"
      src={`${payload?.url}`}
      frameBorder="0"
      allowFullScreen
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div className="mt-2">
        <label
          htmlFor="project-id"
          className="block text-sm font-medium text-gray-700"
        >
     Rundown Studio link
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="project-id"
            id="project-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://app.rundownstudio.app/..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
