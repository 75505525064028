import React from 'react'
import ProfileHeader from './ProfileHeader.js'
import { Helmet } from 'react-helmet'

export default function ProfileLayout({ title, children }) {
  return (
    <div className='text-white'>
      <Helmet>
        <title>{ title }</title>
      </Helmet>
      <ProfileHeader />
      <main className='mx-auto max-w-5xl px-3 sm:px-6 mt-8 mb-20'>
        {children}
      </main>
    </div>
  )
}
