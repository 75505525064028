import { useEffect, useRef, useState, useCallback } from 'react'
import { parseTwitterWidget } from '../../utils/twitterScriptHelper.js'

// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-loading-and-initialization
// https://developer.twitter.com/en/docs/twitter-for-websites/timelines/overview

// We use this to keep track when we last refreshed the widget
let lastRefresh = Date.now()

export function ModuleViewer({ payload }) {
  const [key, setKey] = useState(0)
  const twitterContainerRef = useRef()

  const scale = (Number(payload.scale) || 100) / 100

  /**
   * Creates or recreates the Twitter widget
   * Will load the new widget in the background and remove the old one when the new one is loaded
   */
  const createTwitterWidget = useCallback(async ({ key, href, theme, limit }) => {
    if (!twitterContainerRef?.current) return
    console.info(`Refresh twitter widget for ${href}`)

    // Create new wrapper
    const isTweet = href?.includes('status')
    const newWrapper = document.createElement('div')
    newWrapper.className = `absolute inset-0 overflow-y-auto ${theme === 'dark' ? 'bg-black' : 'bg-white'}`
    newWrapper.style.zIndex = '2'
    newWrapper.dataset.twitterWidgetWrapper = 1
    newWrapper.dataset.key = key
    if (isTweet) {
      const widget = document.createElement('blockquote')
      widget.className = 'twitter-tweet'
      widget.dataset.dnt = 'true'
      widget.dataset.align = 'center'
      widget.dataset.theme = theme || 'light'
      widget.dataset.chrome = 'noheader nofooter noborders'
      const widgetInner = document.createElement('a')
      widgetInner.href = href
      widget.appendChild(widgetInner)
      newWrapper.appendChild(widget)
    } else {
      const widget = document.createElement('a')
      widget.className = 'twitter-timeline'
      widget.dataset.dnt = 'true'
      widget.dataset.theme = theme || 'light'
      widget.dataset.chrome = 'noheader nofooter noborders'
      widget.dataset.tweetLimit = limit || 8
      widget.href = href
      newWrapper.appendChild(widget)
    }
    twitterContainerRef.current.appendChild(newWrapper)

    requestIdleCallback(async () => {
      // load twitter widget
      await parseTwitterWidget(newWrapper)

      // remove old wrappers
      const oldWrappers = twitterContainerRef.current.querySelectorAll(`:scope > [data-twitter-widget-wrapper]:not([data-key="${key}"])`)
      oldWrappers.forEach(el => el.remove())
      newWrapper.style.zIndex = '3'
    }, { timeout: 3000 })
  }, [])

  /**
   * Call `createTwitterWidget()` when any dependency changes
   */
  useEffect(() => {
    createTwitterWidget({ key, ...payload })
  }, [createTwitterWidget, payload, key])

  /**
   * Responsible to call `createTwitterWidget()`
   * Keeps track when the last refresh took place, only refreshes if time for `refreshrate` expired
   * @idempotent [Cann be executed arbitrarily often]
   */
  const refresh = useCallback(() => {
    const refreshrate = Number(payload.refreshrate) || 60000
    if (refreshrate !== -1 && (lastRefresh + refreshrate) < Date.now()) {
      lastRefresh = Date.now()
      setKey(key + 1)
    }
  }, [payload, key])

  /**
   * Execute `refresh()` every second
   * Interval restarts whenever `refresh()` callback is renewed
   */
  useEffect(() => {
    const interval = setInterval(refresh, 1000)
    return () => clearInterval(interval)
  }, [refresh])

  return payload.href ? (
    <div
      ref={twitterContainerRef}
      className="relative h-full"
      style={{ width: `${100 / scale}%`, height: `${100 / scale}%`, transform: `scale(${scale})`, transformOrigin: '0 0' }}
    >
      <div
        className="text-lg font-semibold text-gray-600 text-center p-6"
        data-placeholder="true"
      >
        Loading Twitter...
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Missing Twitter URL]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  const [invalid, setInvalid] = useState([])

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    if (key === 'href') validateTwitterUrl(value || '')
    onUpdatePayload(newPayload)
  }

  function validateTwitterUrl (url = '') {
    if (!url) return setInvalid([])
    const regex = /^https:\/\/twitter\.com\/(?!search)[\w_-]{2,}(\/status\/\d+|\/lists\/[\w_-]+)?(\?.+)?$/i
    if (regex.test(url.trim())) setInvalid([])
    else setInvalid(['href'])
  }

  return (
    <div className="space-y-3">
      <div className="p-2 mt-2 text-sm bg-gray-200 block text-gray-600 rounded">
        The following URL are accepted.
        <br/>A Profile: <strong>https://twitter.com/NASA</strong>
        <br/>A List: <strong>https://twitter.com/TwitterDev/lists/national-parks</strong>
        <br/>A Tweet: <strong>https://twitter.com/NASA/status/1606029815877869574</strong>
      </div>

      <div>
        <label htmlFor="twitter-href" className="block text-sm font-medium text-gray-700">
          Enter a Twitter URL
          {invalid.includes('href') ? <span className="text-red-500 ml-2">(Invalid URL)</span> : ''}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="twitter-href"
            id="twitter-href"
            className="px-2 h-8 shadow-sm block w-full sm:text-sm rounded-md"
            placeholder="https://twitter.com/..."
            value={payload?.href || ''}
            onChange={(e) => onUpdate('href', e.target.value)}
          />
        </div>
      </div>

      <div>
        <label htmlFor="twitter-limit" className="block text-sm font-medium text-gray-700">
          Tweet Limit
        </label>
        <input
          type="number"
          id="twitter-limit"
          name="twitter-limit"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.limit || 8}
          onChange={(e) => onUpdate('limit', e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="twitter-theme" className="block text-sm font-medium text-gray-700">
          Theme
        </label>
        <select
          id="twitter-theme"
          name="twitter-theme"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.theme || 'light'}
          onChange={(e) => onUpdate('theme', e.target.value)}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </div>

      <div>
        <label htmlFor="twitter-refreshrate" className="block text-sm font-medium text-gray-700">
          Refresh
        </label>
        <select
          id="twitter-refreshrate"
          name="twitter-refreshrate"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.refreshrate || '60000'}
          onChange={(e) => onUpdate('refreshrate', e.target.value)}
        >
          <option value="20000">Every 20 seconds</option>
          <option value="60000">Every minute</option>
          <option value="600000">Every 10 minutes</option>
          <option value="-1">Never</option>
        </select>
      </div>

      <div>
        <label htmlFor="twitter-scale" className="block text-sm font-medium text-gray-700">
          Scale
        </label>
        <select
          id="twitter-scale"
          name="twitter-scale"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.scale || '100'}
          onChange={(e) => onUpdate('scale', e.target.value)}
        >
          <option value="200">200%</option>
          <option value="150">150%</option>
          <option value="100">100%</option>
          <option value="75">75%</option>
          <option value="50">50%</option>
          <option value="33">33%</option>
          <option value="25">25%</option>
        </select>
      </div>
    </div>
  )
}
