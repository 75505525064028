export function ModuleViewer({ payload }) {
  return (
    <iframe
      title="Frame"
      width="100%"
      height="100%"
      src={`http://localhost:4001/${payload?.page || 'rundown'}/${
        payload?.projectId
      }/${(payload?.page === 'output' && payload?.output !== '1') ? payload?.output : ''}`}
      frameBorder="0"
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <span className="p-2 text-sm bg-gray-600 block text-gray-200 rounded">
        <span className="font-semibold">Heads up:</span> H2R Graphics must be
    running on this computer.
      </span>
      <div className="mt-2">
        <label
          htmlFor="project-id"
          className="block text-sm font-medium text-gray-700"
        >
     H2R Graphics Project ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="project-id"
            id="project-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste Project ID..."
            value={payload?.projectId || ''}
            onChange={(e) => onUpdate('projectId', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="page" className="block text-sm font-medium text-gray-700">
     Page
        </label>
        <select
          id="page"
          name="page"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.page || 'rundown'}
          onChange={(e) => onUpdate('page', e.target.value)}
        >
          <option value="rundown">Rundown</option>
          <option value="buttons">Buttons</option>
          <option value="preview">Preview</option>
          <option value="output">Output</option>
        </select>
      </div>
      {payload?.page ==='output' && <div>
        <label htmlFor="output-number" className="block text-sm font-medium text-gray-700">
     Output
        </label>
        <input
          id="output-number"
          name="output-number"
          type="text"
          placeholder="1, 2, 3, 4, preview, utility"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={payload?.output}
          onChange={(e) => onUpdate('output', e.target.value)}
        />
      </div>}
    </div>
  )
}
