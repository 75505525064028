import React from 'react'
import UpgradeButton from '../util/UpgradeButton'
import ModalWrapper from './ModalWrapper'

export default function ModalUpgrade ({
  show,
  heading = 'Upgrade',
  body='This feature is available on our paid subscription.',
  closable,
  onClose,
}) {
  return (
    <ModalWrapper
      show={show}
      heading={heading}
      closeable={closable}
      onClose={onClose}
      hideFooter
    >
      <span>{body}</span>
      <UpgradeButton text='Upgrade my account' />
    </ModalWrapper>
  )
}
