export function ModuleViewer({ payload }) {
  //  https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fravendota23%2Fvideos%2F567515892047733%2F&show_text=false&width=560&t=0
  let embedUrl  = new URL('https://www.facebook.com/plugins/video.php')
  embedUrl.searchParams.append('href', `https://www.facebook.com/${payload?.channelId}/videos/${payload?.sourceId}`,)
  embedUrl.searchParams.append('width', '1920')
  embedUrl.searchParams.append('height', '1080')
  embedUrl.searchParams.append('show_text', false)
  embedUrl.searchParams.append('t', '0')

  return (payload?.sourceId && payload?.channelId) ? (
    <iframe
      title="Facebook video player"
      width="100%"
      height="100%"
      src={embedUrl.href}
      frameBorder="0"
      scrolling="no"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
      allowFullScreen
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Facebook Channel or Video ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }


  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="facebook-channel-id" className="block text-sm font-medium text-gray-700">
        Channel ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="facebook-channel-id"
            id="facebook-channel-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="ABCD1234"
            value={payload?.channelId}
            onChange={(e) => onUpdate('channelId', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="facebook-source-id" className="block text-sm font-medium text-gray-700">
        Video ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="facebook-source-id"
            id="facebook-source-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="1234567890"
            value={payload?.sourceId}
            onChange={(e) => onUpdate('sourceId', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
