export const layoutButtons = [
  {
    id: 'layout1',
    svg: (
      <rect
        x="3"
        y="3"
        width="24"
        height="18"
        rx="2"
        fill="currentColor"
        strokeWidth="2"
      />
    ),
  },
  {
    id: 'layout2',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout3',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="10"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="3"
          width="10"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout4',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout6',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="3"
          width="10"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout5',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout7',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout8',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="10"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="14"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="17"
          y="3"
          width="10"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout9',
    svg: (
      <>
        <rect
          x="12"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout10',
    svg: (
      <>
        <rect
          x="12"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="24"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout11',
    svg: (
      <>
        <rect
          x="12"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="15"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout12',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="14"
          width="15"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout13',
    svg: (
      <>
        <rect
          x="3"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="15"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout14',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="15"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout15',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout16',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout17',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout18',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="15"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="14"
          width="15"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout19',
    svg: (
      <>
        <rect
          x="12"
          y="3"
          width="15"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="21"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="14"
          width="6"
          height="7"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout20',
    svg: (
      <>
        <rect
          x="21"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="12"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
        <rect
          x="3"
          y="3"
          width="6"
          height="18"
          rx="2"
          fill="currentColor"
          strokeWidth="2"
        />
      </>
    ),
  },
  {
    id: 'layout21',
    svg: (
      <>
        <rect x="4" y="3" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="10" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="17" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout22',
    svg: (
      <>
        <rect x="4" y="3" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="10" width="5" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="10" width="14" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="17" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout23',
    svg: (
      <>
        <rect x="4" y="3" width="22" height="11" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="17" width="22" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout24',
    svg: (
      <>
        <rect x="4" y="3" width="7" height="18" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="14" y="17" width="12" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="14" y="3" width="12" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="14" y="10" width="12" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout25',
    svg: (
      <>
        <rect x="20" y="3" width="7" height="18" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="17" width="12" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="3" width="12" height="11" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout26',
    svg: (
      <>
        <rect x="4" y="11" width="5" height="10" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="13" y="11" width="5" height="10" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="22" y="11" width="5" height="10" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="3" width="23" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout27',
    svg: (
      <>
        <rect x="4" y="16" width="5" height="5" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="13" y="16" width="5" height="5" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="22" y="16" width="5" height="5" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="4" y="3" width="23" height="10" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout28',
    svg: (
      <>
        <rect x="3" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout29',
    svg: (
      <>
        <rect x="3" y="3" width="24" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="17" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout30',
    svg: (
      <>
        <rect x="3" y="17" width="24" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="3" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="3" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="12" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="21" y="10" width="6" height="4" rx="2" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout31',
    svg: (
      <>
        <rect x="2" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="2" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="2" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout32',
    svg: (
      <>
        <rect x="2" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="14" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="14" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout33',
    svg: (
      <>
        <rect x="2" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="2" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="14" width="11" height="9" rx="2" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
  {
    id: 'layout34',
    svg: (
      <>
        <rect x="2" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="2" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="9" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="17" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="20" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="14" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="8" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
        <rect x="24" y="2" width="4" height="3" rx="1.5" fill="currentColor" strokeWidth="2"/>
      </>
    ),
  },
]

export default layoutButtons
