export function ModuleViewer ({ payload }) {

  return (
    <div className="h-full flex items-center justify-center">
      <div
        className="h-full w-full flex items-center justify-center"
        style={{
          backgroundImage: `url(${payload?.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: payload?.size || 'cover',
          backgroundPosition: 'center',
        }}
      >
        {payload?.text && (
          <div
            className="h-100 p-8"
          >
            <span className="font-semibold text-xl">{payload?.text}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3 mt-3">
      <div>
        <label
          htmlFor="text-value"
          className="block text-sm font-medium text-gray-700"
        >
          Text (optional)
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="text-value"
            id="text-value"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter text..."
            value={payload?.text || ''}
            onChange={(e) => onUpdate('text', e.target.value)}
          />
        </div>
      </div>

      <div>
        <label htmlFor="image-url" className="block text-sm font-medium text-gray-700">
          Image URL (optional)
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="image-url"
            id="image-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste URL..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="image-size"
          className="block text-sm font-medium text-gray-700"
        >
          Size
        </label>
        <select
          id="image-size"
          name="image-size"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.size || 'cover'}
          onChange={(e) => onUpdate('size', e.target.value)}
        >
          <option value="cover">Cover</option>
          <option value="contain">Contain</option>
        </select>
      </div>
    </div>
  )
}
