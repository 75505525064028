import { Popover, Transition } from '@headlessui/react'
import UpgradeButton from '../util/UpgradeButton'
import { useIsPaidUser } from '../util/useIsPaidUser'
import ChangeLayoutButton from './ChangeLayoutButton'

export default function MoreLayouts({ screen, layouts, setScreen, disabled, onEditCustom }) {

  const isPaidUser = useIsPaidUser()

  return (
    <Popover className="relative">
      <Popover.Button>
        <span
          className={`h-8 w-8 flex items-center justify-center bg-gray-900 rounded hover:bg-gray-700 transition
        `}
        >
          <svg
            className="w-6 h-4 text-gray-400 inline"
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14"
              y="11"
              width="2"
              height="2"
              rx="1"
              stroke="currentColor"
              strokeWidth="2"
            />
            <rect
              x="7"
              y="11"
              width="2"
              height="2"
              rx="1"
              stroke="currentColor"
              strokeWidth="2"
            />
            <rect
              x="21"
              y="11"
              width="2"
              height="2"
              rx="1"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </span>
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute p-2 pb-0 z-50 top-[-17.75rem] sm:top-[-15.25rem] -right-[8.8rem] sm:-right-3 bg-gray-800 rounded-t-lg border-4 border-b-0 border-gray-900">
          {!isPaidUser && <span className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800/80 rounded'>
            <UpgradeButton />
          </span>}
          <div className="grid grid-cols-7 grid-rows-2 w-max gap-2">
            {layouts.map((layout, i) => {
              return (
                <div key={layout.id} className={`${i < 6 ? 'sm:hidden' : 'visible'}`}>
                  <ChangeLayoutButton
                    button={layout}
                    setScreen={setScreen}
                    selected={screen.layout === layout.id}
                    disabled={(disabled || !isPaidUser)}
                  />
                </div>
              )
            })}
          </div>
          <button 
            disabled={(disabled || !isPaidUser)}
            className={`mt-2 mb-1 px-2 py-1 w-full hover:bg-gray-700 transition-colors rounded ${screen.layout === 'layoutCustom' ? 'bg-gray-700' : 'bg-gray-900'} `} 
            onClick={onEditCustom}>
            <span className='flex items-center justify-center space-x-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M10 1a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 1zM5.05 3.05a.75.75 0 011.06 0l1.062 1.06A.75.75 0 116.11 5.173L5.05 4.11a.75.75 0 010-1.06zm9.9 0a.75.75 0 010 1.06l-1.06 1.062a.75.75 0 01-1.062-1.061l1.061-1.06a.75.75 0 011.06 0zM3 8a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5A.75.75 0 013 8zm11 0a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5A.75.75 0 0114 8zm-6.828 2.828a.75.75 0 010 1.061L6.11 12.95a.75.75 0 01-1.06-1.06l1.06-1.06a.75.75 0 011.06 0zm3.594-3.317a.75.75 0 00-1.37.364l-.492 6.861a.75.75 0 001.204.65l1.043-.799.985 3.678a.75.75 0 001.45-.388l-.978-3.646 1.292.204a.75.75 0 00.74-1.16l-3.874-5.764z" clipRule="evenodd" />
              </svg>
              <span>Custom layout</span>
            </span>
          </button>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
