import { nanoid } from 'nanoid/non-secure'

function createButton (sampleData = false) {
  return {
    id: nanoid(),
    label: sampleData ? 'Click Me' : '',
    url: sampleData ? 'https://dashmaster2k.com/' : '',
  }
}

export function ModuleViewer ({ payload }) {
  const buttons = payload?.buttons
  
  return buttons?.length >= 1 ? (
    <div
      className="flex flex-wrap items-center justify-center gap-6 h-full p-3"
    >

      {/* Actual buttons */}
      {buttons.map(button => (
        <a
          key={button.id}
          className={`relative flex space-x-2 items-center rounded text-white shadow px-4 py-2 ${button.colour || 'bg-gray-700'} filter hover:brightness-125 cursor-pointer`}
          target="_blank"
          rel="noreferrer"
          href={button.url}
        >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          </span>
          {button.label
            ? (<span>{button.label}</span>)
            : (<span className="text-gray-500">[Label missing]</span>)
          }
        </a>
      ))}
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[No links added]</span>
    </div>
  )
}

function ModuleEditorItem ({ button, onUpdate, onRemove }) {
  function updateItem (key, value) {
    const update = { ...button, [key]: value }
    onUpdate(update)
  }

  const colours = ['bg-gray-500', 'bg-red-500', 'bg-indigo-500', 'bg-green-600', 'bg-teal-600', 'bg-yellow-600']

  return (
    <div className="rounded bg-gray-200 shadow p-3 space-y-2">
      <div className="flex items-center">
        <label
          className="flex-none w-16 font-semibold text-gray-400 text-sm"
          htmlFor={`button-label-${button.id}`}
        >
          Label
        </label>
        <input
          id={`button-label-${button.id}`}
          className="flex-none mr-2 w-36 px-2 h-8 shadow-sm sm:text-sm rounded"
          type="text"
          placeholder="Label..."
          value={button.label || ''}
          onChange={(e) => updateItem('label', e.target.value)}
        />
        {colours.map((colour, index) => {
          const c = button.colour || colours[0]

          return (
            <button  
              key={index}
              className={`mx-0.5 w-6 h-6 ${colour} ${(c === colour) && 'border-2 border-gray-900'} rounded-full`} 
              onClick={() => updateItem('colour', colour)}>
            </button>)
        })}
        <span className="flex-auto"></span>
        <button
          className="flex-none text-xs text-gray-500 hover:text-gray-700"
          onClick={onRemove}
        >
          Remove
        </button>
      </div>
      <div className="flex items-center">
        <div className="flex-none w-16 font-semibold text-gray-400 text-sm">
          Target
        </div>
        {/* <select
          className="flex-none w-20 pl-2 pr-5 h-8 bg-white shadow-sm sm:text-sm rounded-l"
          value={button.method || 'get'}
          onChange={(e) => updateItem('method', e.target.value)}
        >
          <option value="get">GET</option>
          <option value="post">POST</option>
        </select> */}
        <input
          type="text"
          className="flex-auto px-2 h-8 shadow-sm w-full sm:text-sm rounded border"
          placeholder="Paste URL..."
          value={button.url || ''}
          onChange={(e) => updateItem('url', e.target.value)}
        />
      </div>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {
  const buttons = payload?.buttons || []

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  function addItem () {
    const newItem = createButton(buttons.length === 0)
    const update = [ ...buttons, newItem]
    onUpdate('buttons', update)
  }

  function removeItem (id) {
    const update = buttons.filter(item => item.id !== id)
    onUpdate('buttons', update)
  }

  function updateItem (item) {
    const index = buttons.findIndex(b => b.id === item.id)
    const update = buttons.slice()
    update.splice(index, 1, item)
    onUpdate('buttons', update)
  }

  return (
    <div className="space-y-3">
      <p className="text-sm text-gray-600">
        All links will open in a new tab.
      </p>
      <div>

      </div>
      <ul className="space-y-2">
        {buttons.map(button => (
          <li key={button.id}>
            <ModuleEditorItem
              button={button}
              onUpdate={updateItem}
              onRemove={() => removeItem(button.id)}
            />
          </li>
        ))}
        <li>
          <button
            className="px-4 h-8 text-xs font-medium rounded shadow-sm text-white bg-gray-500 hover:bg-gray-600"
            onClick={addItem}
          >
            + Add Link
          </button>
        </li>
      </ul>
    </div>
  )
}
