import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ScreenContent from '../ScreenContent'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import InputEditable from '../util/InputEditable.js'
import ModalWrapper from '../modals/ModalWrapper'

export default function ScreensPreview( { screen: savedScreen } ) {
  const [screen, setScreen] = useState(savedScreen)
  const [editingName, setEditingName] = useState(false)
  const [showMoreMenu, setShowMoreMenu] = useState(false)
  const [dashboardToDelete, setDashboardToDelete] = useState(null)
  const [deletingDashboard, setDeletingDashboard] = useState(false)

  const navigate = useNavigate()
  const containerRef = useRef()

  async function onChangeName (name = '') {
    const docRef = doc(db, 'screens', screen?.id)
    setScreen({ ...screen, name })
    await updateDoc(docRef, { name, updated: new Date() })
  }

  function handleOutsideClick (event) {
    if (containerRef?.current?.contains(event.target)) return
    setShowMoreMenu(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  })

  async function handleDeleteDashboard () {
    setDeletingDashboard(true)
    await deleteDoc(doc(db, 'screens', dashboardToDelete))

    setScreen(null)

    return handleCloseMenu()
  }

  function handleCloseMenu () {
    setDashboardToDelete(null)
    setDeletingDashboard(false)
    return setShowMoreMenu(false)
  }

  if(!screen) return

  return (
    <div
      id={`screen-${screen?.id}`}
      ref={containerRef}
      key={screen?.id}
      className='flex flex-col bg-gray-700 rounded transition-transform transform hover:scale-105 group z-10'
    >
      {/* bg-gray-900/75 */}
      <div className='p-0.5 pb-0'>
        <div className='relative aspect-video rounded-sm overflow-hidden'>
          <Link to={`/s/${screen.id}`} className='absolute flex items-center justify-center w-full h-full bg-gray-900/75 transition-opacity opacity-0 group-hover:opacity-100'>
            <span className='mr-1'>Open</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          </Link>
          <ScreenContent screen={screen} profile={true} />
        </div>
      </div>
      <div className='p-1 flex-1 text-sm flex items-center space-x-3'>
        <div className="flex-1">
          <InputEditable
            inputClass="w-full"
            value={screen.name}
            onUpdateValue={onChangeName}
            onUpdateEditing={(val) => setEditingName(val)}
            placeholder="Dashboard"
          />
        </div>
        {!editingName ? (
          <span className='flex-0 flex items-center px-0.5 text-xs text-gray-400 rounded-sm'>
            {screen.id}
            <button onClick={()=> setShowMoreMenu(!showMoreMenu)} className={`ml-1 p-1 hover:bg-gray-900 rounded ${showMoreMenu && 'bg-gray-900'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
              </svg>
            </button>
          </span>
        ) : ''}
        {showMoreMenu && <div className='absolute right-[0.4rem] bottom-9'>
          <div className='bg-gray-200 rounded z-20'>
            <ul className='flex flex-col'>
              <li className='flex-1'>
                <button
                  className='w-full text-gray-900 p-2 flex items-center space-x-2 hover:bg-gray-100 rounded'
                  onClick={() => navigate(`/s?copy_from=${screen.id}`)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path d="M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z" />
                    <path d="M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z" />
                  </svg>

                  <span>Duplicate dashboard</span>
                </button>
              </li>
              <li className='flex-1'>
                <button
                  className='w-full text-gray-900 p-2 flex items-center space-x-2 hover:bg-gray-100 rounded'
                  onClick={() => setDashboardToDelete(screen.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                  </svg>
                  <span>Delete dashboard</span>
                </button>
              </li>
            </ul>
          </div>
        </div>}
        <ModalWrapper
          show={Boolean(dashboardToDelete)}
          heading="Delete Dashboard"
          acceptButtonText="Yes, delete this dashboard"
          onClose={handleCloseMenu}
          onAccept={() => handleDeleteDashboard()}
          loading={deletingDashboard}
        >
          Are you sure you want to delete this dashboard?
        </ModalWrapper>
      </div>
    </div>
  )
}
