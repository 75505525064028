import React, { useCallback, useEffect, useState } from 'react'
import { getAuthUser } from '../../firebase'
import ProfileLayout from './ProfileLayout'
import { collection, query, where, getDocs, orderBy, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import Spinner from '../util/Spinner'
import { customAlphabet } from 'nanoid/non-secure'
import { DEVICE_LIMIT } from '../util/limits'
import { useIsPaidUser } from '../util/useIsPaidUser'
import ModalUpgrade from '../modals/ModalUpgrade'
import InputEditable from '../util/InputEditable'
import ModalWrapper from '../modals/ModalWrapper'

export default function Devices() {
  // SCREENS
  const [screens, setScreens] = useState([])
  const [loadingScreens, setLoadingScreens] = useState(true)
  const [errorScreens, setErrorScreens] = useState(null)

  // DEVICES
  const [devices, setDevices] = useState([])
  const [loadingDevices, setLoadingDevices] = useState(true)
  const [errorDevices, setErrorDevices] = useState(null)
  const [creatingNewDevice, setCreatingNewDevice] = useState(false)
  const [deviceToDelete, setDeviceToDelete] = useState(null)
  const [deletingDevice, setDeletingDevice] = useState(false)

  const isPaidUser = useIsPaidUser()
  const [userDeviceCount, setUserDeviceCount] = useState(0)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)


  const getUserDevices = useCallback(
    async () => {
      const currentUser = await getAuthUser()
  
      const devicesRef = collection(db, 'devices')
      const q = query(devicesRef, where('uid', '==', currentUser.uid), orderBy('created', 'desc'))
  
      const querySnapshot = await getDocs(q)
      let data = []
  
      if(querySnapshot.empty){
        setLoadingDevices(false)
        console.info(loadingDevices)
        return setErrorDevices('No devices...yet.')
      }
  
  
      setUserDeviceCount(querySnapshot?.docs?.length)
      querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
      })
  
      setLoadingDevices(false)
      setErrorDevices('')
      return setDevices(data)
    },
    [loadingDevices],
  )
  


  const getUserScreens = useCallback(
    async () => {
      const currentUser = await getAuthUser()

      const screensRef = collection(db, 'screens')
      const q = query(screensRef, where('uid', '==', currentUser.uid), orderBy('updated', 'desc'))

      const querySnapshot = await getDocs(q)
      let data = []

      if(querySnapshot.empty){
        setLoadingScreens(false)
        console.info(errorScreens)
        return setErrorScreens('No dashboards... yet.')
      }

      querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
      })

      setLoadingScreens(false)
      setScreens(data)
      return getUserDevices()
    },
    [errorScreens, getUserDevices],
  )
  

  const handleUpdateDevice = async (deviceId, update) => {
    const docRef = doc(db, 'devices', deviceId)

    await updateDoc(docRef, {
      updated: new Date(),
      ...update,
    })

    return getUserDevices()

  }

  const handleIdentifyDevice = async (deviceId) => {
    const docRef = doc(db, 'devices', deviceId)

    await updateDoc(docRef, {
      updated: new Date(),
      identifiedAt: new Date(),
    })
  }

  const handleCreateNewDevice = async () => {
    setCreatingNewDevice(true)
    const currentUser = await getAuthUser()
    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
    const id = nanoid(8)
    const docRef = doc(db, 'devices', id)

    await setDoc(docRef, {
      updated: new Date(),
      created: new Date(),
      uid: currentUser.uid,
    })

    getUserDevices()
    return setCreatingNewDevice(false)
  }


  async function handleDeleteDashboard () {
    setDeletingDevice(true)
    await deleteDoc(doc(db, 'devices', deviceToDelete))

    await getUserDevices()

    return handleCloseMenu()
  }

  function handleCloseMenu () {
    setDeviceToDelete(null)
    return setDeletingDevice(false)
  }


  useEffect(() => {
    if(loadingScreens) {
      getUserScreens()
    }
  }, [loadingScreens, getUserScreens])

  return (
    <ProfileLayout title="Devices">
      <div className='flex justify-between items-center gap-4 mb-4'>
        <h2 className='font-semibold  text-2xl'>My devices</h2>
        <button
          className="inline-block rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 transition-colors px-4 py-2"
          onClick={()=>{
            if(userDeviceCount >= DEVICE_LIMIT && !isPaidUser) {
              return setShowUpgradeModal(true)
            }

            handleCreateNewDevice()
          }}
          disabled={creatingNewDevice}
        >
          {creatingNewDevice ? 'Creating...' : 'Create new device'}
        </button>
        <ModalUpgrade
          show={showUpgradeModal}
          heading='Create new device'
          body={<span>You've already created {DEVICE_LIMIT} device, which is the limit on the free plan.</span>}
          onClose={() => setShowUpgradeModal(false)}
        />
      </div>
      <span className='text-sm text-gray-500'>Create and use devices to update content remotely.</span>
      {loadingScreens &&
        <div className="w-8 my-8">
          <Spinner />
        </div>
      }
      {errorDevices &&
        <span className='block text-sm text-gray-300'>
          {errorDevices}
        </span>
      }
      <div className='mt-4 flex flex-col space-y-2'>
        {devices.length > 0 && devices.map((device) => {
          const {id, screenId = '', name = ''} = device
          return (
            <div
              key={id}
              className='p-4 flex flex-col space-y-2 justify-between bg-gray-800 rounded divide-y-2 divide-gray-900'
            >
              <div className='flex space-x-6'>
                {/* Device ID */}
                <div className='flex-0 flex-col space-y-1 whitespace-nowrap'>
                  <div className='font-semibold text-xs text-gray-500'>Device ID</div>
                  <div className='text-sm select-all leading-7 font-mono'>
                    {id}
                  </div>
                </div>
                {/* Device ID */}
                <div className='flex-1 flex-col space-y-1 whitespace-nowrap'>
                  <div className='pl-2 font-semibold text-xs text-gray-500'>Name</div>
                  <InputEditable
                    inputClass="w-full"
                    value={name}
                    onUpdateValue={(val)=>{
                      handleUpdateDevice(id, {name: val})
                    }}
                    placeholder="Device"
                  />
                </div>
                <button onClick={()=> setDeviceToDelete(id)} className="ml-1 px-3 text-gray-300 hover:bg-gray-900 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </button>
              </div>

              
              <ModalWrapper
                show={Boolean(deviceToDelete)}
                heading="Delete Device"
                acceptButtonText="Yes, delete this device"
                onClose={handleCloseMenu}
                onAccept={() => handleDeleteDashboard()}
                loading={deletingDevice}
              >
                Are you sure you want to delete this device?
              </ModalWrapper>

              
              <div className='flex flex-col md:flex-row space-x-0 md:space-x-6 space-y-4 md:space-y-0 pt-4'>
                {/* Identify */}
                <div className='flex-0 flex-col space-y-1 whitespace-nowrap'>
                  <div className='font-semibold text-xs text-gray-500'>Identify</div>
                  <button
                    className='px-2 h-7 text-sm bg-gray-800 hover:bg-gray-600 transition-colors rounded focus:outline-none focus:ring'
                    onClick={() => handleIdentifyDevice(id)}
                  >
                    Show me
                  </button>
                </div>
                {/* Device URL */}
                <div className='flex-0 flex-col space-y-1 whitespace-nowrap'>
                  <div className='font-semibold text-xs text-gray-500'>Device URL</div>
                  <div className='text-sm h-7 flex items-center'>
                    <a
                      className='block leading-5 border-b border-dotted border-gray-500 hover:border-gray-300'
                      href={window.location.origin + '/d/' + id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {window.location.origin + '/d/' + id}
                    </a>
                  </div>
                </div>
                {/* Dropdown */}
                <div className='flex-1 flex-col space-y-1 min-w-0'>
                  <div className='font-semibold text-xs text-gray-500'>Change dashboard on device</div>
                  <select
                    className='px-2 h-7 bg-gray-900 rounded focus:outline-none focus:ring w-full'
                    defaultValue={screenId}
                    onChange={(e)=> handleUpdateDevice(id, {screenId: e.target.value})}
                  >
                    <option value={''} disabled>Choose dashboard...</option>
                    {screens.length > 0 && screens.map((screen) => {
                      const {name = 'Dashboard', id: screenId} = screen
                      return (
                        <option key={screenId} value={screenId}>{name || 'Dashboard'} • {screenId}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </ProfileLayout>
  )
}
