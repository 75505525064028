// https://stackoverflow.com/questions/5433806/convert-embedded-svg-to-png-in-place
// http://phrogz.net/SVG/svg_to_png.xhtml

export function convertSvgToDataUrl (svgElement) {
  const svgAsXML = (new XMLSerializer()).serializeToString(svgElement)
  return 'data:image/svg+xml,' + encodeURIComponent(svgAsXML)
}

export function convertSvgToCanvas (svgElement, width = 500, height = 500) {
  let resolveWithCanvas
  const promise = new Promise(resolve => resolveWithCanvas = resolve)
  try {
    const canvasEl = document.createElement('canvas')
    const context  = canvasEl.getContext('2d')

    const sourceImage = new Image()
    sourceImage.width  = canvasEl.width = width
    sourceImage.height = canvasEl.height = height
    sourceImage.onload = () => {
      context.drawImage(sourceImage, 0, 0, width, height)
      resolveWithCanvas(canvasEl)
    }
    sourceImage.src = convertSvgToDataUrl(svgElement)
  } catch(err) {
    console.error(err)
  }
  return promise
}
