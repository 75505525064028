import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase.js'
import { useEffect } from 'react'
import Spinner from './util/Spinner.js'
import { signOut } from 'firebase/auth'
import { Helmet } from 'react-helmet'

export default function Logout () {
  const navigate = useNavigate()

  useEffect(() => {
    logout()
  })

  async function logout () {
    await signOut(auth)
    navigate('/', { replace: true })
  }

  return (
    <main className="flex items-center justify-center h-screen text-white">
      <Helmet>
        <title>Logout</title>
      </Helmet>
      <section>
        <h1 className="text-3xl text-center">Logout</h1>
        <div className="w-8 mx-auto my-8">
          <Spinner />
        </div>
      </section>
    </main>
  )
}
