import { useEffect, useRef, useState } from 'react'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'


export function ModuleViewer({ payload }) {
  const [error, setError] = useState(null)
  const [name, setName] = useState('')
  const [joiningMeeting, setJoiningMeeting] = useState(false)
  
  const client = ZoomMtgEmbedded.createClient()

  const meetingSizeRef = useRef()

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  let signatureEndpoint = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE + '/zoomMeetingSDKSignature'
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  let sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY
  let meetingNumber = payload?.meetingId
  let role = 0
  let userName = name
  let userEmail = ''
  let password = payload?.meetingPasscode
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/webinars#join-registered
  let registrantToken = ''

  async function getSignature() {
    const response = await fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res)=> res.json())
      .catch((error)=> setError({reason: 'Failed to get signature'}))

    if(!response.signature) return setError({reason: 'Failed to get signature'})
    return response.signature
  }

  async function startMeeting() {
    setJoiningMeeting(true)
    const signature = await getSignature()

    let meetingSDKElement = document.getElementById('meetingSDKElement')
    let meetingSizeRefDimensions = meetingSizeRef.current.getBoundingClientRect()

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      showMeetingHeader: false,
      language: 'en-US',
      customize: {
        meetingInfo: ['topic'],
        video: {
          isResizable: false,
          viewSizes: {
            default: {
              width: meetingSizeRefDimensions?.width || 500,
              height: meetingSizeRefDimensions?.height - 75 || 500,
            },
            ribbon: {
              width: 300,
              height: 700,
            },
          },
        },
      },
    }).catch(error => setError(error))

   
    client.join({
      sdkKey,
      signature,
      meetingNumber,
      password,
      userName,
      userEmail,
      tk: registrantToken,
    })
      .catch(error => setError(error))
    
  }

  useEffect(() => {
    client.leaveMeeting()
    setJoiningMeeting(false)
    setError(null)
    
  }, [client, payload?.meetingId, payload?.meetingPasscode])
  
  if(error) {
    return (
      <div className="flex justify-center items-center h-full">
        <span className="text-lg font-semibold text-gray-600">{error.reason}</span>
      </div>
    )
  }

  if(!joiningMeeting) {
    return (
      <div className='flex items-center justify-center h-full w-100'>
        <div className='flex flex-col space-y-2'>
          <div className='mt-2'>
            <label htmlFor="meeting-name" className="block text-sm font-medium text-gray-200">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="meeting-name"
                id="meeting-name"
                className="px-2 h-8 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="My name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <button onClick={()=> startMeeting()} className='py-2 px-3 bg-gray-700 hover:bg-gray-600 rounded pointer-events-auto'>Join Zoom meeting</button>
        </div>
      </div>
    )
  }
  return payload?.meetingId ? (
    <div ref={meetingSizeRef} className='flex items-center justify-center h-full w-100'>
      <div id="meetingSDKElement" className='flex items-center justify-center h-full w-100'>
        {/* ZOOM MEETING WILL RENDER HERE */}
      </div>  
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Zoom Meeting ID missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }


  return (
    <div className="space-y-3">
        
      <div>
        <label htmlFor="meeting-id" className="block text-sm font-medium text-gray-700">
          Meeting ID
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="meeting-id"
            id="meeting-id"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="1234567890"
            value={payload?.meetingId}
            onChange={(e) => onUpdate('meetingId', e.target.value)}
          />
        </div>
      </div>
        
      <div>
        <label htmlFor="meeting-passcode" className="block text-sm font-medium text-gray-700">
          Passcode
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="meeting-passcode"
            id="meeting-passcode"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="012345"
            value={payload?.meetingPasscode}
            onChange={(e) => onUpdate('meetingPasscode', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
