export function ModuleViewer ({ payload }) {
  return payload?.text ? (
    <div
      className="flex items-center justify-center h-full p-8"
    >
      <span className="text-gray-200 font-semibold text-xl">{payload?.text}</span>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Text empty]</span>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="text-value" className="block text-sm font-medium text-gray-700">
          Text
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="text-value"
            id="text-value"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter text..."
            value={payload?.text || ''}
            onChange={(e) => onUpdate('text', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
