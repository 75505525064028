import { useEffect, useRef, useState } from 'react'
import 'hls-video-element'

export function ModuleViewer({ payload }) {
  const [url, setUrl] = useState(payload?.url)
  const videoRef = useRef()

  useEffect(() => {
    setUrl(payload?.url)
  }, [payload?.url])

  return url ? (
    <div className="relative w-full h-full aspect-video">
      <hls-video 
        ref={videoRef}
        controls
        autoplay
        muted
        src={url}
      ></hls-video>
    </div>
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[HLS URL missing]</span>
    </div>
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div className="mt-2">
        <label
          htmlFor="hls-url"
          className="block text-sm font-medium text-gray-700"
        >
        HLS URL
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="hls-url"
            id="hls-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste URL..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      {/* <div className="mt-2">
        <label
          htmlFor="hls-label"
          className="block text-sm font-medium text-gray-700"
        >
        Label
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="hls-label"
            id="hls-label"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Stream 1"
            value={payload?.label || ''}
            onChange={(e) => onUpdate('label', e.target.value)}
          />
        </div>
      </div> */}
    </div>
  )
}
