import ProfileLayout from './ProfileLayout'
import { Link, useSearchParams } from 'react-router-dom'

export default function ChackoutSuccess() {
  const [searchParams] = useSearchParams()

  return (
    <ProfileLayout title="Thank you!">
      <div
        id="checkout-success-page"
        className="py-24 font-thin text-center space-y-8"
      >
        <h1 className="text-5xl font-semibold mb-12">Thank you!</h1>

        {searchParams.get('plan_name') ? (
          <p className="text-xl">
            You purchased the <span className="font-semibold">{searchParams.get('plan_name')}</span> package.
          </p>
        ) : ''}

        <p className="text-xl">
          You are awesome! All your dashboards are now licensed.
        </p>
        <p>
          <Link
            className="rounded text-white font-semibold bg-indigo-600 hover:bg-indigo-700 transition-colors px-6 py-3"
            to="/dashboards"
          >
            Back to Dashboards
          </Link>
        </p>
        <p className="text-neutral-500">
          A confirmation email with a receipt will arrive in your inbox shortly.
        </p>

        <img
          className="blok mx-auto rounded-lg"
          src="/img/good-job.gif"
          alt="You are awesome"
          width="320"
          height="240"
        />
      </div>
    </ProfileLayout>
  )
}
