import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { collection, getFirestore, query, where, getCountFromServer } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export const firebaseConfig = {
  apiKey: 'AIzaSyD9-Ks9ahZhgUSC29DuqXhiOG0JlHUefI4',
  authDomain: 'dashmaster-2000-app.firebaseapp.com',
  projectId: 'dashmaster-2000-app',
  storageBucket: 'dashmaster-2000-app.appspot.com',
  messagingSenderId: '274356969822',
  appId: '1:274356969822:web:8647e9fed53f61bd542fcf',
  measurementId: 'G-P3KHYGWF8T',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)

// Init Auth
let resolveAuthUser = undefined
let authUser = new Promise(r => resolveAuthUser = r)
onAuthStateChanged(auth, (user) => {
  authUser = Promise.resolve(user)
  resolveAuthUser(user)
})

/**
 * Get the authenticated user or `null`, can be awaited
 * @returns {Promise<Object|null>}
 */
export async function getAuthUser () {
  const currentUser = await authUser
  return await currentUser
}

/**
 * Returns a boolean if the user is authenticated or not, can be awaited
 * @returns {Promise<boolean>}
 */
export async function isAuthenticated () {
  const user = await getAuthUser()
  return Boolean(user)
}

/**
 * Returns an Integar with how many screens the current user has created
 * @returns {Promise<number>}
 */
export async function getUserScreenCount () {
  try {
    const currentUser = await getAuthUser()
    const screensRef = await collection(db, 'screens')
    const snapshot = await getCountFromServer(query(screensRef, where('uid', '==', currentUser.uid)))
    return snapshot.data().count
  } catch (error) {
    return 0
  }
}

/**
 * Returns an Integar with how many devices the current user has created
 * @returns {Promise<number>}
 */
export async function getUserDeviceCount () {
  try {
    const currentUser = await getAuthUser()
    const devicesRef = await collection(db, 'devices')
    const snapshot = await getCountFromServer(query(devicesRef, where('uid', '==', currentUser.uid)))
    return snapshot.data().count
  } catch (error) {
    return 0
  }
}
