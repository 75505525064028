import React, { useEffect, useState } from 'react'
import { getAuthUser, getUserDeviceCount, getUserScreenCount } from '../../firebase'
import ProfileLayout from './ProfileLayout'
import Spinner from '../util/Spinner'
import UpgradeButton from '../util/UpgradeButton'
import UserIcon from '../auth/UserIcon'
import {useIsPaidUser} from '../util/useIsPaidUser'
import { DASHBOARD_LIMIT, DASHBOARD_LIMIT_PRO, DEVICE_LIMIT, DEVICE_LIMIT_PRO} from '../util/limits'

export default function Profile() {
  const [user, setUser] = useState(null)
  const [screenCount, setScreenCount] = useState(0)
  const [deviceCount, setDeviceCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const isPaidUser = useIsPaidUser()

  const getCurrentUser = async () => {
    const currentUser = await getAuthUser()
    const countDashboards = await getUserScreenCount()
    const countDevices = await getUserDeviceCount()

    setLoading(false)
    setScreenCount(countDashboards)
    setDeviceCount(countDevices)
    return setUser(currentUser)
  }


  useEffect(() => {
    getCurrentUser()
  }, [])

  return (
    <ProfileLayout title="Profile">
      <div className='flex justify-between items-center gap-4 mb-4'>
        <h2 className='font-semibold text-2xl'>My profile</h2>
        {/* <Link to={'/s'} className='rounded text-white text-sm bg-indigo-600 hover:bg-indigo-700 transition-colors px-4 py-2'>
          Create new dashboard
        </Link> */}
      </div>
      {loading &&
        <div className="w-8 my-8">
          <Spinner />
        </div>
      }
      {user &&
          <div className='mt-2'>

            <div className='p-4 flex items-center space-x-4 border-2 border-gray-800 rounded'>
              <UserIcon user={user} size='w-10 h-10' />
              <div>
                <p>
                  <span className='text-gray-200 w-12 inline-block'>Name</span> <span className='text-gray-400'>{user?.displayName || '-'}</span>
                </p>
                <p>
                  <span className='text-gray-200 w-12 inline-block'>Email</span> <span className='text-gray-400'>{user?.email}</span>
                </p>

              </div>
            </div>

            {
              isPaidUser === null &&
              <div className="w-8 my-8">
                <Spinner />
              </div>
            }

            {isPaidUser === true &&
              <div className='mt-4 p-4 flex items-center space-x-4 bg-gray-800 rounded'>
                <span className='p-2 text-white bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-600 hover:to-indigo-800 rounded'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                  </svg>
                </span>

                <div>
                  <h2 className='text-lg font-semibold'>You're a pro 🎉</h2>
                  <p className='text-sm text-gray-400'>You are currently on a paid plan!</p>
                  <p className='text-sm text-gray-300'>You've created {screenCount}/{DASHBOARD_LIMIT_PRO} dashboards and {deviceCount}/{DEVICE_LIMIT_PRO} devices.</p>
                </div>
                {/* <UpgradeButton /> */}
              </div>
            }
            {isPaidUser === false &&
              <div className='mt-4 p-4 flex justify-between items-center bg-gray-800 rounded'>
                <div>
                  <h2 className='text-lg font-semibold'>You're on the free plan</h2>
                  <p className='text-sm text-gray-400'>Upgrade for more dashboards, devices and layouts.</p>
                  <p className='text-sm text-gray-300'>You've created {screenCount}/{DASHBOARD_LIMIT} dashboards and {deviceCount}/{DEVICE_LIMIT} devices.</p>
                </div>
                <UpgradeButton text='Upgrade for more' />
              </div>
            }

          </div>
      }

    </ProfileLayout>
  )
}
