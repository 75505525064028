import InputEditable from './util/InputEditable.js'
import { Helmet } from 'react-helmet'

export default function ScreenHeader ({ screenId, screen, onChangeName, disabled = false }) {
  return (
    <div className="flex justify-between items-center space-x-8 h-full px-4">
      <Helmet>
        <title>{ screen.name || 'Dashboard ' + screenId }</title>
      </Helmet>
      {/* Logo */}
      <div className="flex-0 w-28 sm:w-36">
        <a
          className="hover:underline"
          href="https://dashmaster2k.com"
          target="_blank"
          rel="noreferrer"
        >
          <svg viewBox="0 0 145 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.26086 1.64666C1.26086 1.43679 1.43022 1.26666 1.63913 1.26666H8.44782C8.65673 1.26666 8.82608 1.43679 8.82608 1.64666V8.48666C8.82608 8.69653 8.65673 8.86666 8.44782 8.86666H1.63913C1.43022 8.86666 1.26086 8.69653 1.26086 8.48666V1.64666Z" fill="#453EDA"/>
            <path d="M1.26086 10.5134C1.26086 10.3035 1.43022 10.1334 1.63913 10.1334H8.44782C8.65673 10.1334 8.82608 10.3035 8.82608 10.5134V17.3534C8.82608 17.5632 8.65673 17.7334 8.44782 17.7334H1.63913C1.43022 17.7334 1.26086 17.5632 1.26086 17.3534V10.5134Z" fill="#453EDA"/>
            <path d="M10.0869 1.64666C10.0869 1.43679 10.2563 1.26666 10.4652 1.26666H17.2739C17.4827 1.26666 17.6522 1.43679 17.6522 1.64666V8.48666C17.6522 8.69653 17.4827 8.86666 17.2739 8.86666H10.4652C10.2563 8.86666 10.0869 8.69653 10.0869 8.48666V1.64666Z" fill="#453EDA"/>
            <path d="M27.8058 15.6469H23.6413V3.33496H27.8898C29.11 3.33496 30.1581 3.58144 31.0342 4.07441C31.9143 4.56336 32.5905 5.26672 33.0625 6.18452C33.5346 7.1023 33.7706 8.20043 33.7706 9.47893C33.7706 10.7614 33.5325 11.8636 33.0565 12.7854C32.5845 13.7072 31.9024 14.4145 31.0103 14.9075C30.1222 15.4005 29.0541 15.6469 27.8058 15.6469ZM25.8675 13.7172H27.6978C28.554 13.7172 29.2681 13.5609 29.84 13.2483C30.4122 12.9317 30.8423 12.4607 31.1302 11.8355C31.4183 11.2063 31.5622 10.4208 31.5622 9.47893C31.5622 8.5371 31.4183 7.75558 31.1302 7.13436C30.8423 6.50915 30.4161 6.04224 29.852 5.73363C29.292 5.42103 28.5959 5.26473 27.7639 5.26473H25.8675V13.7172Z" fill="white"/>
            <path d="M38.2564 15.8333C37.6723 15.8333 37.1462 15.7291 36.6781 15.5207C36.2141 15.3083 35.8461 14.9957 35.574 14.5829C35.3059 14.1701 35.172 13.6611 35.172 13.0559C35.172 12.5349 35.2679 12.104 35.4599 11.7634C35.652 11.4227 35.914 11.1502 36.2461 10.9458C36.5782 10.7414 36.9522 10.5871 37.3682 10.4829C37.7883 10.3747 38.2223 10.2965 38.6704 10.2484C39.2104 10.1923 39.6485 10.1422 39.9845 10.0981C40.3206 10.05 40.5645 9.9779 40.7166 9.88171C40.8727 9.78152 40.9507 9.62723 40.9507 9.41881V9.38274C40.9507 8.92986 40.8167 8.57919 40.5486 8.3307C40.2805 8.08221 39.8946 7.95796 39.3905 7.95796C38.8584 7.95796 38.4364 8.0742 38.1243 8.30665C37.8163 8.5391 37.6083 8.81363 37.5002 9.13025L35.4719 8.84169C35.632 8.2806 35.896 7.81168 36.264 7.43496C36.6321 7.05421 37.0822 6.76966 37.6143 6.58129C38.1464 6.38891 38.7344 6.29272 39.3785 6.29272C39.8225 6.29272 40.2646 6.34483 40.7047 6.44903C41.1447 6.55323 41.5468 6.72558 41.9108 6.96604C42.2749 7.20249 42.5668 7.52513 42.7868 7.93393C43.011 8.34271 43.123 8.85372 43.123 9.46691V15.6469H41.0347V14.3785H40.9627C40.8306 14.635 40.6446 14.8754 40.4046 15.0999C40.1686 15.3203 39.8706 15.4987 39.5105 15.6349C39.1544 15.7672 38.7364 15.8333 38.2564 15.8333ZM38.8205 14.2342C39.2564 14.2342 39.6345 14.148 39.9546 13.9757C40.2745 13.7993 40.5206 13.5669 40.6927 13.2783C40.8687 12.9898 40.9567 12.6752 40.9567 12.3345V11.2464C40.8886 11.3025 40.7726 11.3546 40.6087 11.4027C40.4485 11.4508 40.2686 11.4929 40.0686 11.5289C39.8685 11.565 39.6704 11.5971 39.4745 11.6251C39.2785 11.6532 39.1084 11.6772 38.9643 11.6972C38.6403 11.7413 38.3503 11.8135 38.0943 11.9137C37.8382 12.0139 37.6362 12.1541 37.4882 12.3345C37.3402 12.5108 37.2663 12.7393 37.2663 13.0198C37.2663 13.4206 37.4122 13.7232 37.7043 13.9276C37.9963 14.132 38.3683 14.2342 38.8205 14.2342Z" fill="white"/>
            <path d="M52.4063 8.85372L50.4259 9.07013C50.37 8.86974 50.272 8.68137 50.132 8.50504C49.996 8.32869 49.8119 8.18641 49.5799 8.0782C49.3479 7.97 49.0639 7.9159 48.7279 7.9159C48.2757 7.9159 47.8957 8.01408 47.5876 8.21046C47.2835 8.40684 47.1337 8.66134 47.1376 8.97394C47.1337 9.24248 47.2315 9.4609 47.4317 9.62922C47.6356 9.79754 47.9717 9.93582 48.4398 10.044L50.0119 10.3807C50.8841 10.5691 51.5322 10.8676 51.9562 11.2764C52.3842 11.6852 52.6003 12.2203 52.6043 12.8816C52.6003 13.4627 52.4303 13.9757 52.0942 14.4206C51.7621 14.8614 51.3 15.2061 50.7081 15.4546C50.1159 15.703 49.4358 15.8273 48.6678 15.8273C47.5397 15.8273 46.6315 15.5908 45.9434 15.1179C45.2553 14.641 44.8453 13.9777 44.7133 13.128L46.8315 12.9236C46.9275 13.3404 47.1316 13.6551 47.4437 13.8675C47.7557 14.0799 48.1617 14.1861 48.6618 14.1861C49.1778 14.1861 49.5919 14.0799 49.9039 13.8675C50.2199 13.6551 50.378 13.3926 50.378 13.0799C50.378 12.8154 50.2761 12.597 50.072 12.4247C49.8719 12.2523 49.5599 12.1201 49.1359 12.0279L47.5636 11.6972C46.6796 11.5129 46.0255 11.2023 45.6014 10.7654C45.1773 10.3246 44.9673 9.76749 44.9713 9.09418C44.9673 8.52507 45.1213 8.03211 45.4334 7.6153C45.7494 7.19448 46.1875 6.86985 46.7475 6.64141C47.3116 6.40896 47.9617 6.29272 48.6978 6.29272C49.7779 6.29272 50.628 6.52318 51.2482 6.98407C51.8722 7.44496 52.2583 8.06818 52.4063 8.85372Z" fill="white"/>
            <path d="M56.382 10.2364V15.6469H54.2097V3.33496H56.3339V7.98201H56.4419C56.658 7.461 56.9919 7.0502 57.4441 6.74961C57.9001 6.44502 58.4802 6.29272 59.1843 6.29272C59.8243 6.29272 60.3824 6.42699 60.8585 6.69551C61.3345 6.96402 61.7025 7.3568 61.9626 7.87381C62.2266 8.39081 62.3587 9.02204 62.3587 9.76749V15.6469H60.1863V10.1041C60.1863 9.48293 60.0264 9 59.7063 8.65533C59.3903 8.30664 58.9463 8.1323 58.3742 8.1323C57.9901 8.1323 57.646 8.21647 57.342 8.3848C57.042 8.54911 56.8059 8.78758 56.634 9.10019C56.466 9.41279 56.382 9.79154 56.382 10.2364Z" fill="white"/>
            <path d="M64.3871 15.6469V6.41296H66.4634V7.98201H66.5714C66.7634 7.45299 67.0814 7.04018 67.5255 6.74361C67.9695 6.44301 68.4996 6.29272 69.1157 6.29272C69.7398 6.29272 70.2659 6.44503 70.694 6.74961C71.126 7.05021 71.43 7.461 71.606 7.98201H71.702C71.9061 7.46901 72.25 7.06021 72.7342 6.75563C73.2222 6.44702 73.8003 6.29272 74.4684 6.29272C75.3165 6.29272 76.0085 6.56124 76.5447 7.09829C77.0807 7.63534 77.3487 8.41887 77.3487 9.44888V15.6469H75.1705V9.78553C75.1705 9.21241 75.0184 8.7936 74.7144 8.52909C74.4104 8.26055 74.0383 8.1263 73.5982 8.1263C73.0742 8.1263 72.6641 8.29062 72.3681 8.61926C72.076 8.94389 71.9301 9.36672 71.9301 9.88773V15.6469H69.7998V9.69536C69.7998 9.21842 69.6558 8.83768 69.3677 8.55312C69.0837 8.26857 68.7117 8.1263 68.2517 8.1263C67.9396 8.1263 67.6556 8.20645 67.3995 8.36676C67.1435 8.52307 66.9394 8.7455 66.7875 9.03406C66.6354 9.31862 66.5594 9.65126 66.5594 10.032V15.6469H64.3871Z" fill="white"/>
            <path d="M82.0189 15.8333C81.4348 15.8333 80.9089 15.7291 80.4408 15.5207C79.9768 15.3083 79.6086 14.9957 79.3366 14.5829C79.0686 14.1701 78.9345 13.6611 78.9345 13.0559C78.9345 12.5349 79.0307 12.104 79.2226 11.7634C79.4146 11.4227 79.6767 11.1502 80.0088 10.9458C80.3407 10.7414 80.7149 10.5871 81.1308 10.4829C81.551 10.3747 81.985 10.2965 82.433 10.2484C82.9731 10.1923 83.4111 10.1422 83.7473 10.0981C84.0833 10.05 84.3273 9.9779 84.4793 9.88171C84.6354 9.78152 84.7134 9.62723 84.7134 9.41881V9.38274C84.7134 8.92986 84.5793 8.57919 84.3113 8.3307C84.0432 8.08221 83.6571 7.95796 83.1531 7.95796C82.621 7.95796 82.1991 8.0742 81.887 8.30665C81.5789 8.5391 81.3708 8.81363 81.2629 9.13025L79.2346 8.84169C79.3946 8.2806 79.6587 7.81168 80.0267 7.43496C80.3948 7.05421 80.8449 6.76966 81.3768 6.58129C81.9089 6.38891 82.4971 6.29272 83.1411 6.29272C83.5852 6.29272 84.0272 6.34483 84.4672 6.44903C84.9074 6.55323 85.3095 6.72558 85.6734 6.96604C86.0375 7.20249 86.3295 7.52513 86.5495 7.93393C86.7737 8.34271 86.8856 8.85372 86.8856 9.46691V15.6469H84.7974V14.3785H84.7254C84.5933 14.635 84.4073 14.8754 84.1673 15.0999C83.9312 15.3203 83.6331 15.4987 83.2732 15.6349C82.9171 15.7672 82.499 15.8333 82.0189 15.8333ZM82.583 14.2342C83.0191 14.2342 83.3972 14.148 83.7172 13.9757C84.0373 13.7993 84.2833 13.5669 84.4552 13.2783C84.6313 12.9898 84.7194 12.6752 84.7194 12.3345V11.2464C84.6513 11.3025 84.5353 11.3546 84.3712 11.4027C84.2112 11.4508 84.0313 11.4929 83.8313 11.5289C83.6313 11.565 83.4331 11.5971 83.2371 11.6251C83.0412 11.6532 82.8711 11.6772 82.727 11.6972C82.403 11.7413 82.113 11.8135 81.8569 11.9137C81.6009 12.0139 81.3989 12.1541 81.2509 12.3345C81.1029 12.5108 81.0288 12.7393 81.0288 13.0198C81.0288 13.4206 81.1749 13.7232 81.467 13.9276C81.7589 14.132 82.131 14.2342 82.583 14.2342Z" fill="white"/>
            <path d="M96.1689 8.85372L94.1887 9.07013C94.1328 8.86974 94.0347 8.68137 93.8946 8.50504C93.7586 8.32869 93.5747 8.18641 93.3427 8.0782C93.1105 7.97 92.8265 7.9159 92.4905 7.9159C92.0384 7.9159 91.6584 8.01408 91.3503 8.21046C91.0463 8.40684 90.8963 8.66134 90.9004 8.97394C90.8963 9.24248 90.9943 9.4609 91.1943 9.62922C91.3984 9.79754 91.7344 9.93582 92.2025 10.044L93.7747 10.3807C94.6467 10.5691 95.2948 10.8676 95.7189 11.2764C96.147 11.6852 96.3629 12.2203 96.367 12.8816C96.3629 13.4627 96.193 13.9757 95.857 14.4206C95.5249 14.8614 95.0628 15.2061 94.4707 15.4546C93.8787 15.703 93.1986 15.8273 92.4304 15.8273C91.3023 15.8273 90.3943 15.5908 89.7061 15.1179C89.018 14.641 88.6079 13.9777 88.476 13.128L90.5943 12.9236C90.6903 13.3404 90.8942 13.6551 91.2063 13.8675C91.5183 14.0799 91.9243 14.1861 92.4244 14.1861C92.9406 14.1861 93.3546 14.0799 93.6667 13.8675C93.9827 13.6551 94.1406 13.3926 94.1406 13.0799C94.1406 12.8154 94.0387 12.597 93.8347 12.4247C93.6347 12.2523 93.3226 12.1201 92.8985 12.0279L91.3264 11.6972C90.4422 11.5129 89.7881 11.2023 89.3641 10.7654C88.94 10.3246 88.7299 9.76749 88.734 9.09418C88.7299 8.52507 88.8841 8.03211 89.1961 7.6153C89.5121 7.19448 89.9501 6.86985 90.5103 6.64141C91.0743 6.40896 91.7243 6.29272 92.4605 6.29272C93.5406 6.29272 94.3908 6.52318 95.0108 6.98407C95.6349 7.44496 96.0209 8.06818 96.1689 8.85372Z" fill="white"/>
            <path d="M102.575 6.413V8.09627H97.2762V6.413H102.575ZM98.5844 4.20068H100.757V12.8696C100.757 13.1621 100.801 13.3866 100.889 13.5429C100.981 13.6952 101.101 13.7994 101.249 13.8555C101.397 13.9116 101.561 13.9397 101.741 13.9397C101.877 13.9397 102.001 13.9296 102.113 13.9096C102.229 13.8896 102.317 13.8715 102.377 13.8555L102.743 15.5568C102.627 15.5969 102.461 15.641 102.245 15.689C102.033 15.7372 101.773 15.7652 101.465 15.7732C100.921 15.7893 100.431 15.7071 99.9945 15.5267C99.5586 15.3424 99.2125 15.0578 98.9565 14.6731C98.7043 14.2883 98.5805 13.8074 98.5844 13.2303V4.20068Z" fill="white"/>
            <path d="M108.187 15.8273C107.263 15.8273 106.465 15.6349 105.793 15.2502C105.125 14.8614 104.611 14.3123 104.251 13.603C103.891 12.8896 103.711 12.0499 103.711 11.0841C103.711 10.1342 103.891 9.30058 104.251 8.58319C104.615 7.86178 105.123 7.30069 105.775 6.8999C106.427 6.49512 107.193 6.29272 108.073 6.29272C108.641 6.29272 109.177 6.38491 109.681 6.56927C110.19 6.74961 110.638 7.03016 111.026 7.41091C111.418 7.79165 111.726 8.27659 111.95 8.86574C112.174 9.45087 112.286 10.1482 112.286 10.9578V11.6251H104.731V10.1583H110.203C110.2 9.74145 110.11 9.37073 109.933 9.04609C109.757 8.71744 109.511 8.45895 109.195 8.27058C108.883 8.08221 108.519 7.98803 108.103 7.98803C107.659 7.98803 107.269 8.09624 106.933 8.31266C106.597 8.52507 106.335 8.80562 106.147 9.1543C105.963 9.49898 105.869 9.87771 105.865 10.2905V11.571C105.865 12.1081 105.963 12.5689 106.159 12.9537C106.355 13.3344 106.629 13.627 106.981 13.8314C107.333 14.0318 107.745 14.132 108.217 14.132C108.533 14.132 108.819 14.0879 109.075 13.9997C109.331 13.9076 109.554 13.7733 109.742 13.5969C109.93 13.4206 110.072 13.2022 110.168 12.9417L112.196 13.1701C112.068 13.7072 111.824 14.1761 111.464 14.5769C111.108 14.9736 110.652 15.2822 110.096 15.5027C109.539 15.7191 108.903 15.8273 108.187 15.8273Z" fill="white"/>
            <path d="M113.938 15.647V6.41302H116.044V7.95202H116.14C116.308 7.41897 116.596 7.00818 117.004 6.71961C117.416 6.42705 117.886 6.28076 118.414 6.28076C118.534 6.28076 118.668 6.28676 118.816 6.2988C118.968 6.30681 119.094 6.32084 119.194 6.34088V8.34277C119.102 8.31071 118.956 8.28265 118.756 8.25861C118.56 8.23056 118.37 8.21653 118.186 8.21653C117.79 8.21653 117.434 8.30269 117.118 8.47502C116.806 8.64336 116.56 8.87781 116.38 9.1784C116.2 9.47898 116.11 9.82565 116.11 10.2184V15.647H113.938Z" fill="white"/>
            <path d="M124.433 15.647V14.0359L128.7 9.84569C129.108 9.43289 129.448 9.06617 129.72 8.74555C129.992 8.42492 130.196 8.11432 130.332 7.81374C130.468 7.51316 130.536 7.19253 130.536 6.85186C130.536 6.46311 130.448 6.13047 130.272 5.85392C130.096 5.57337 129.854 5.35696 129.546 5.20466C129.238 5.05236 128.888 4.97622 128.496 4.97622C128.092 4.97622 127.738 5.06037 127.434 5.22871C127.13 5.39303 126.894 5.62747 126.726 5.93207C126.562 6.23666 126.48 6.59937 126.48 7.02019H124.361C124.361 6.23867 124.539 5.55934 124.895 4.98222C125.251 4.40509 125.741 3.95823 126.365 3.64161C126.994 3.32499 127.714 3.16669 128.526 3.16669C129.35 3.16669 130.074 3.32098 130.698 3.62959C131.322 3.93818 131.806 4.36101 132.15 4.89806C132.498 5.4351 132.672 6.04829 132.672 6.73764C132.672 7.19853 132.584 7.65142 132.408 8.09628C132.232 8.54115 131.922 9.03411 131.478 9.57517C131.038 10.1162 130.42 10.7715 129.624 11.541L127.506 13.6992V13.7834H132.858V15.647H124.433Z" fill="white"/>
            <path d="M136.747 12.7493L136.741 10.1222H137.089L140.401 6.41296H142.94L138.865 10.9578H138.415L136.747 12.7493ZM134.767 15.6469V3.33496H136.939V15.6469H134.767ZM140.551 15.6469L137.551 11.4448L139.015 9.91178L143.15 15.6469H140.551Z" fill="white"/>
          </svg>
        </a>
      </div>

      {/* Name */}
      <div className="flex-1 w-full">
        <h1 className="text-2xl font-semibold">
          <InputEditable
            inputClass="text-center w-full"
            value={screen.name}
            placeholder="Dashboard"
            onUpdateValue={onChangeName}
            disabled={disabled}
          />
        </h1>
      </div>

      <div className="flex-0 w-28 sm:w-36"></div>
    </div>
  )
}
