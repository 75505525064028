import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Popover } from '@headlessui/react'

const states = {
  OK: 'OK',
  LOADING: 'LOADING',
  FRAME_OPTIONS_DENY: 'FRAME_OPTIONS_DENY',
  URL_MISSING: 'URL_MISSING',
}

// We use this to keep track when we last refreshed the iFrame
let lastRefresh = Date.now()

export function ModuleViewer({ payload, onUpdatePayload }) {
  const [key, setKey] = useState(0)
  const [state, setState] = useState(null)

  const scale = (Number(payload.scale) || 100) / 100

  // Check if the URL can be embedded
  const memoizedCheckHttpHeaders = useCallback(checkHttpHeaders, [payload])
  async function checkHttpHeaders () {
    setState(states.LOADING)

    if (!payload?.url) return setState(states.URL_MISSING)
    if (payload?.bypassScan || payload?.url.includes('http://')) return setState(states.OK)

    const functionUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE + '/checkHttpHeaders'
    try {
      const { data } = await axios.get(functionUrl, { params: { url: payload?.url } })
      if (data['x-frame-options']) return setState(states.FRAME_OPTIONS_DENY)
    } catch (err) {
      console.error(`Request to ${functionUrl} failed`, { name: err.name, message: err.message })
    }

    setState(states.OK)
  }
  useEffect(() => {
    memoizedCheckHttpHeaders()
  }, [memoizedCheckHttpHeaders])

  function onBypassScan () {
    onUpdatePayload({ ...payload, bypassScan: true })
  }

  /**
   * Responsible to increment the `key` which refreshes the iFrame
   * Keeps track when the last refresh took place, only refreshes if time for `refreshrate` expired
   * @idempotent [Cann be executed arbitrarily often]
   */
  const refresh = useCallback(() => {
    const refreshrate = Number(payload.refreshrate) || -1
    if (refreshrate !== -1 && (lastRefresh + refreshrate) < Date.now()) {
      lastRefresh = Date.now()
      setKey(key + 1)
    }
  }, [payload, key])

  /**
   * Execute `refresh()` every second
   * Interval restarts whenever `refresh()` callback is renewed
   */
  useEffect(() => {
    const interval = setInterval(refresh, 1000)
    return () => clearInterval(interval)
  }, [refresh])

  switch (state) {
  case states.LOADING: return (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">Loading iFrame ...</span>
    </div>
  )

  case states.FRAME_OPTIONS_DENY: return (
    <div className="relative flex flex-col justify-center items-center space-y-2 h-full">
      <span className="text-lg font-semibold text-gray-600">
        Oh no, this page prevents us from embedded it 😕
      </span>
      <code className='px-1 rounded bg-gray-800 text-sm text-gray-600'>
        {payload?.url}
      </code>
      <div className='flex items-center space-x-2'>
        <button
          className='text-gray-500 hover:text-gray-400 border-b border-dotted border-current'
          onClick={() => onBypassScan()}
        >
          Load anyway
        </button>

        <Popover className="inline-block">
          <Popover.Button className='w-4 h-4 text-gray-500 hover:text-gray-400 border border-current rounded-full text-xs font-bold'>i</Popover.Button>
          <Popover.Panel className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-60 bg-gray-900 rounded p-3 text-xs shadow-lg">
            {({ close }) => <>
              <p className='mb-2'>Our scan indicates that this page prevents embedding. But some pages block scans. You can overwrite the scan and load the page anyway.</p>
              <button className='text-gray-400' onClick={() => close()}>Close</button>
            </>}
          </Popover.Panel>
        </Popover>
      </div>
    </div>
  )

  case states.URL_MISSING: return (
    <div className="flex flex-col justify-center items-center space-y-2 h-full">
      <span className='text-xl'>⚠️</span>
      <span className="text-lg font-semibold text-gray-600">
        [iFrame URL missing]
      </span>
    </div>
  )

  default:
  case states.OK: return (
    <div
      className="w-full h-full"
      style={{ width: `${100 / scale}%`, height: `${100 / scale}%`, transform: `scale(${scale})`, transformOrigin: '0 0' }}
    >
      <iframe
        title="Frame"
        width="100%"
        height="100%"
        key={key}
        src={payload?.url}
        frameBorder="0"
        data-key={key}
        allowFullScreen
        allow="screen-wake-lock; autoplay"
      />
    </div>
  )
  }
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div className="p-2 mt-2 text-sm bg-gray-200 block text-gray-600 rounded">
        Note: Some websites prevent the embedding as an iFrame. In this case you can try the <a href="https://dashmaster2k.com/docs/screenshot/" target="_blank" rel="noopener" className="font-bold hover:underline">Website Screenshot</a> module instead.
      </div>
      <div>
        <label htmlFor="iframe-url" className="block text-sm font-medium text-gray-700">
          iFrame URL
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="iframe-url"
            id="iframe-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Paste URL..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="twitter-refreshrate" className="block text-sm font-medium text-gray-700">
          Refresh
        </label>
        <select
          id="twitter-refreshrate"
          name="twitter-refreshrate"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.refreshrate || '-1'}
          onChange={(e) => onUpdate('refreshrate', e.target.value)}
        >
          <option value="10000">Every 10 seconds</option>
          <option value="30000">Every 30 seconds</option>
          <option value="60000">Every minute</option>
          <option value="600000">Every 10 minutes</option>
          <option value="-1">Never</option>
        </select>
      </div>

      <div>
        <label htmlFor="twitter-scale" className="block text-sm font-medium text-gray-700">
          Scale
        </label>
        <select
          id="twitter-scale"
          name="twitter-scale"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.scale || '100'}
          onChange={(e) => onUpdate('scale', e.target.value)}
        >
          <option value="200">200%</option>
          <option value="150">150%</option>
          <option value="100">100%</option>
          <option value="75">75%</option>
          <option value="50">50%</option>
          <option value="33">33%</option>
          <option value="25">25%</option>
        </select>
      </div>
      {/*<div>
        <label htmlFor="iframe-reload">
          <input
            id="iframe-reload"
            name="iframe-reload"
            className="w-4 h-4 mr-3"
            type="checkbox"
            checked={payload?.reload60sec || false}
            onChange={(e) => onUpdate('reload60sec', e.target.checked)}
          />
          <span>Reload every 60 sec</span>
        </label>
      </div>*/}
    </div>
  )
}
