import { useEffect, useRef, useState, useCallback } from 'react'

export default function InputEditable ({
  value = '',
  onUpdateValue,
  onUpdateEditing = () => {},
  inputClass = '',
  placeholder = '',
  disabled = false,
}) {
  const [editing, setEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  useEffect(() => setInputValue(value), [value])
  useEffect(() => onUpdateEditing(editing), [editing, onUpdateEditing])

  const inputRef = useRef()
  const wrapperRef = useRef()

  function onEdit () {
    if (disabled) return
    setEditing(true)
    inputRef.current.focus()
  }

  function onSave () {
    onUpdateValue(inputValue)
    setEditing(false)
    inputRef.current.setSelectionRange(0, 0)
    inputRef.current.blur()
  }

  function onCancel () {
    setEditing(false)
    setInputValue(value)
    inputRef.current.setSelectionRange(0, 0)
    inputRef.current.blur()
  }

  // eslint-disable-next-line
  const memoizedOnOutsideClick = useCallback(onOutsideClick, [value, wrapperRef])
  function onOutsideClick (event) {
    if (wrapperRef?.current.contains(event.target)) return
    if(!editing) return onCancel()
    onSave()
  }

  useEffect(() => {
    document.addEventListener('mousedown', memoizedOnOutsideClick)
    return () => document.removeEventListener('mousedown', memoizedOnOutsideClick)
  }, [memoizedOnOutsideClick])

  return (
    <div
      ref={wrapperRef}
      className="relative block"
    >
      <input
        ref={inputRef}
        className={`
          bg-transparent text-ellipsis ${inputClass}
          border rounded border-transparent
          placeholder:text-gray-100/25
          ${editing ? '!border-gray-600 pl-2 pr-8' : '' }
          ${!editing && !disabled ? 'hover:border-gray-600 px-2' : '' }
          ${disabled ? 'select-none' : ''}
        `}
        readOnly={!editing}
        value={inputValue}
        placeholder={placeholder}
        onChange={(e)=> setInputValue(e.target.value)}
        onKeyDown={(e)=> {
          if (e.code === 'Enter') return onSave()
          if (e.code === 'Escape') return onCancel()
        }}
        onClick={() => onEdit()}
        disabled={disabled}
      />
      <div className="absolute h-5 right-1 top-1/2 -translate-y-1/2">
        {editing ? (
          <button
            className="block w-5 h-5 p-1 text-white bg-green-500 hover:bg-green-600 rounded"
            onClick={() => onSave()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </button>
        ) : ''}
      </div>
    </div>
  )
}
