export default function ChangeLayoutButton({ button, setScreen, selected, disabled }) {
  return (
    <button
      className={
        `h-8 w-8 flex items-center justify-center rounded transition-colors
        ${selected ? 'bg-gray-700' : 'bg-gray-900'}
        ${disabled ? 'pointer-events-none' : 'hover:bg-gray-700'}
     `}
      onClick={() => setScreen(button.id)}
      disabled={disabled}
    >
      <svg
        className="w-6 h-4 text-gray-400 inline"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {button.svg}
      </svg>
    </button>
  )
}
