export function ModuleViewer({ payload }) {
  return (
    <iframe
      title="Frame"
      width="100%"
      height="100%"
      src={`https://heretorecord.com/test-patterns/${payload?.video_pattern || 'v1'}`}
      frameBorder="0"
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="video_pattern" className="block text-sm font-medium text-gray-700">
     Videos and patterns
        </label>
        <select
          id="video_pattern"
          name="video_pattern"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.video_pattern || 'v1'}
          onChange={(e) => onUpdate('video_pattern', e.target.value)}
        >
          <option value="mid-label-video" disabled={true}>Videos</option>
          <option value="v1">Nodding Participant #1</option>
          <option value="v2">Nodding Participant #2</option>
          <option value="v3">Nodding Participant #3</option>
          <option value="v4">Nodding Participant #4</option>
          <option value="v5">Talking Participant #1</option>
          <option value="v6">Talking Participant #2</option>
          <option value="v7">Talking Participant #3</option>
          <option value="v8">Talking Participant #4</option>
          <option value="v9">Fishteen by 9</option>
          <option value="mid-label-pattern" disabled={true}>Patterns</option>
          <option value="p1">Colour bars</option>
          <option value="p2">Time of Day + Screen size</option>
          <option value="p3">EBU 75% colour bars</option>
        </select>
      </div>
    </div>
  )
}
