export function ModuleViewer ({ payload }) {
  return payload?.url ? (
    <iframe
      title="Mentimeter"
      width="100%"
      height="100%"
      src={`${payload?.url}${payload?.url.includes('/presentation') ? '/embed' : ''}`}
      frameBorder="0"
    />
  ) : (
    <div className="flex justify-center items-center h-full">
      <span className="text-lg font-semibold text-gray-600">[Mentimeter link missing]</span>
    </div>
  )
}

export function ModuleEditor ({ payload, onUpdatePayload }) {

  function onUpdate (key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="mentimeter-url" className="block text-sm font-medium text-gray-700">
        Mentimeter URL
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="mentimeter-url"
            id="mentimeter-url"
            className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="https://www.mentimeter.com/app/presentation/..."
            value={payload?.url || ''}
            onChange={(e) => onUpdate('url', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
