const DASHBOARD_LIMIT = 3
const DEVICE_LIMIT = 1

const DASHBOARD_LIMIT_PRO = 100
const DEVICE_LIMIT_PRO = 10


module.exports = {
  DASHBOARD_LIMIT,
  DEVICE_LIMIT,
  DASHBOARD_LIMIT_PRO,
  DEVICE_LIMIT_PRO,
}