import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <main className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-3 text-white">Dashmaster 2k</h1>

        <div className="flex justify-center space-x-3 mb-3">
          <Link to="/login" className="rounded bg-gray-700 hover:bg-gray-800 text-gray-200 transition-colors px-2 py-1">
            Login
          </Link>
          <Link to="/signup" className="rounded bg-gray-700 hover:bg-gray-800 text-gray-200 transition-colors px-2 py-1">
            Sign up
          </Link>
        </div>

        <Link  to="/s/" className="inline-block rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 transition-colors px-6 py-3">
          Create a dashboard
        </Link>
      </div>
    </main>
  )
}

